import React, { useState, useEffect, createContext } from 'react';
import { BASE_URL } from '../BaseUrl';
import { getCookie } from '../functions/Functions';
import { useSafeAsyncRequest } from '../hooks/useSafeAsyncRequest';

const FoodContext = createContext();

const FoodProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [dataPaginating, setDataPaginating] = useState(false);
    const [foodListWeb, setFoodListWeb] = useState(null);
    const [foodStockWeb, setFoodStockWeb] = useState(null);
    const [foodGroupWeb, setFoodGroupWeb] = useState(null);
    const [propertyGroupWeb, setPropertyGroupWeb] = useState(null);
    const [workPeriodWeb, setWorkPeriodWeb] = useState(null);
    const [onlineOrdersCustomer, setOnlineOrdersCustomer] = useState(null);
    const [onlineOrdersAdmin, setOnlineOrdersAdmin] = useState(null);
    const [onlineOrdersAdminForSearch, setOnlineOrdersAdminForSearch] = useState(null);
    const [foodList, setFoodList] = useState(null);
    const [foodForSearch, setFoodForSearch] = useState(null);
    const [foodItemStock, setFoodItemStock] = useState(null);
    const [foodPurchaseHistory, setFoodPurchaseHistory] = useState(null);
    const [foodPurchaseHistoryForSearch, setFoodPurchaseHistoryForSearch] = useState(null);
    const [foodGroupList, setFoodGroupList] = useState(null);
    const [foodGroupForSearch, setFoodGroupforSearch] = useState(null);
    const [foodUnitList, setFoodUnitList] = useState(null);
    const [foodUnitForSearch, setFoodUnitforSearch] = useState(null);
    const [variationList, setVariationList] = useState(null);
    const [variationForSearch, setVariationForSearch] = useState(null);
    const [propertyGroupList, setPropertyGroupList] = useState(null);
    const [propertyGroupForSearch, setPropertyGroupForSearch] = useState(null);
    const [propertyItemList, setPropertyItemList] = useState(null);
    const [propertyItemForSearch, setPropertyItemForSearch] = useState(null);
    const [propertyItemGroup, setPropertyItemGroup] = useState(null);
    let [items, setItems] = useState(null);
    let [theGroups, setTheGroups] = useState(null);
    let [itemsFood, setItemsFood] = useState(null);
    let [theGroupsFood, setTheGroupsFood] = useState(null);
    const safeAxiosGet = useSafeAsyncRequest();

    useEffect(() => {
        getFoodWeb();
        if (getCookie() !== undefined) {
            getFood();
            getFoodGroup();
            getFoodUnit();
            getVariation();
            getPropertyGroup();
        }
    }, []);

    const getIngredientStock = async (id) => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-closing-items/" + id;
        const data = await safeAxiosGet(url, );
        if (data) {
            setItems(data[0]);
            setTheGroups(data[1]);
        }
        setLoading(false);

    };

    const getFoodStockReport = async (id) => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-closing-items-food/" + id;
        const data = await safeAxiosGet(url, );
        if (data) {
            setItemsFood(data[0]);
            setTheGroupsFood(data[1]);
        }
        setLoading(false);
    };

    const getFoodWeb = async () => {
        setLoading(true);
        const foodItemWebUrl = BASE_URL + "/website/home";
        const data = await safeAxiosGet(foodItemWebUrl);
        if (data) {
            setFoodListWeb(data[0]);
            setFoodGroupWeb(data[1]);
            setPropertyGroupWeb(data[2]);
            setWorkPeriodWeb(data[3]);
            setFoodStockWeb(data[4]);
        }
        setLoading(false);

    };

    const getOnlineOrdersCustomer = async () => {
        setLoading(true);
        const ordersUrl = BASE_URL + "/website/online-orders/customer";
        const data = await safeAxiosGet(ordersUrl, );
        if (data) {
            setOnlineOrdersCustomer(data[1]);
            setLoading(false);
        }
    };

    const getOnlineOrders = async () => {
        setLoading(true);
        const ordersUrl = BASE_URL + "/website/online-orders";
        const data = await safeAxiosGet(ordersUrl, );
        if (data) {
            setOnlineOrdersAdmin(data[0]);
            setOnlineOrdersAdminForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedOnlineOrders = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/website/online-orders?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setOnlineOrdersAdmin(data[0]);
            setOnlineOrdersAdminForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getFood = async () => {
        setLoading(true);
        const foodItemUrl = BASE_URL + "/settings/get-food-item";
        const data = await safeAxiosGet(foodItemUrl, );
        if (data) {
            setFoodList(data[0]);
            setFoodForSearch(data[1]);
        }
    };

    const setPaginatedFood = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-food-item?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setFoodList(data[0]);
            setFoodForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getFoodGroup = async () => {
        setLoading(true);
        const foodGroupUrl = BASE_URL + "/settings/get-food-group";
        const data = await safeAxiosGet(foodGroupUrl, );
        if (data) {
            setFoodGroupList(data[0]);
            setFoodGroupforSearch(data[1]);
            setFoodItemStock(data[2]);
            setLoading(false);
        }
    };

    const setPaginatedFoodGroup = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-food-group?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setFoodGroupList(data[0]);
            setFoodGroupforSearch(data[1]);
            setFoodItemStock(data[2]);
            setDataPaginating(false);
        }
    };

    const getFoodUnit = async () => {
        setLoading(true);
        const foodUnitUrl = BASE_URL + "/settings/get-food-unit";
        const data = await safeAxiosGet(foodUnitUrl, );
        if (data) {
            setFoodUnitList(data[0]);
            setFoodUnitforSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedFoodUnit = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-food-unit?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setFoodUnitList(data[0]);
            setFoodUnitforSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getVariation = async () => {
        setLoading(true);
        const variationUrl = BASE_URL + "/settings/get-variation";
        const data = await safeAxiosGet(variationUrl, );
        if (data) {
            setVariationList(data[0]);
            setVariationForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedVariation = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-variation?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setVariationList(data[0]);
            setVariationForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getPropertyGroup = async () => {
        setLoading(true);
        const propertyGroupUrl = BASE_URL + "/settings/get-property-group";
        const data = await safeAxiosGet(propertyGroupUrl, );
        if (data) {
            setPropertyGroupList(data[0]);
            setPropertyGroupForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedPropertyGroup = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-property-group?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setPropertyGroupList(data[0]);
            setPropertyGroupForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getPropertyItem = async (slug) => {
        setLoading(true);
        const propertyItemUrl = BASE_URL + "/settings/get-property-item/" + slug;
        const data = await safeAxiosGet(propertyItemUrl, );
        if (data) {
            setPropertyItemList(data[0]);
            setPropertyItemForSearch(data[0]);
            setPropertyItemGroup(data[1]);
            setLoading(false);
        }
    };

    const getFoodPurchase = async () => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-food_purchase";
        const data = await safeAxiosGet(url);
        if (data) {
            setFoodPurchaseHistory(data[0]);
            setFoodPurchaseHistoryForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedFoodPurchase = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-food_purchase?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setFoodPurchaseHistory(data[0]);
            setFoodPurchaseHistoryForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    return (
        <FoodContext.Provider
            value={{
                loading,
                setLoading,
                getIngredientStock,
                items,
                setItems,
                theGroups,
                setTheGroups,
                getFoodStockReport,
                itemsFood,
                setItemsFood,
                theGroupsFood,
                setTheGroupsFood,
                getFoodWeb,
                foodGroupWeb,
                foodListWeb,
                setFoodListWeb,
                foodStockWeb,
                setFoodStockWeb,
                propertyGroupWeb,
                setPropertyGroupWeb,
                workPeriodWeb,
                onlineOrdersAdmin,
                setOnlineOrdersAdmin,
                onlineOrdersAdminForSearch,
                setOnlineOrdersAdminForSearch,
                getOnlineOrders,
                setPaginatedOnlineOrders,
                getOnlineOrdersCustomer,
                onlineOrdersCustomer,
                setOnlineOrdersCustomer,
                getFood,
                foodList,
                setFoodList,
                setPaginatedFood,
                foodForSearch,
                setFoodForSearch,
                getFoodPurchase,
                foodPurchaseHistory,
                setFoodPurchaseHistory,
                setPaginatedFoodPurchase,
                foodPurchaseHistoryForSearch,
                setFoodPurchaseHistoryForSearch,
                foodItemStock,
                getFoodGroup,
                foodGroupList,
                setFoodGroupList,
                setPaginatedFoodGroup,
                foodGroupForSearch,
                setFoodGroupforSearch,
                getFoodUnit,
                foodUnitList,
                setFoodUnitList,
                setPaginatedFoodUnit,
                foodUnitForSearch,
                setFoodUnitforSearch,
                getVariation,
                variationList,
                setVariationList,
                setPaginatedVariation,
                variationForSearch,
                setVariationForSearch,
                getPropertyGroup,
                propertyGroupList,
                setPropertyGroupList,
                setPaginatedPropertyGroup,
                propertyGroupForSearch,
                setPropertyGroupForSearch,
                getPropertyItem,
                propertyItemList,
                setPropertyItemList,
                propertyItemForSearch,
                setPropertyItemForSearch,
                propertyItemGroup,
                setPropertyItemGroup,
                dataPaginating,
                setDataPaginating,
            }}
        >
            {children}
        </FoodContext.Provider>
    );
};

export { FoodContext, FoodProvider };
