import React, { useState, useEffect, createContext } from 'react';
import { BASE_URL } from '../BaseUrl';
import { getCookie, deleteCookie } from '../functions/Functions';
import { useSafeAsyncRequest } from '../hooks/useSafeAsyncRequest';

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [dataPaginating, setDataPaginating] = useState(false);
    const [authUserInfo, setAuthUserInfo] = useState({
        details: null,
        permissions: null,
    });
    const [supplierList, setSupplierList] = useState(null);
    const [supplierForSearch, setSupplierForSearch] = useState(null);
    const [waiterList, setWaiterList] = useState(null);
    const [waiterForSearch, setWaiterForSearch] = useState(null);
    const [customerList, setCustomerList] = useState(null);
    const [customerForSearch, setCustomerForSearch] = useState(null);
    const [customerListOnline, setCustomerListOnline] = useState(null);
    const [customerOnlineForSearch, setCustomerOnlineForSearch] = useState(null);
    const [adminStaffList, setAdminStaffList] = useState(null);
    const [adminStaffForSearch, setAdminStaffListforSearch] = useState(null);
    const [deliveryList, setDeliveryList] = useState(null);
    const [deliveryForSearch, setDeliveryListforSearch] = useState(null);
    const [deliveryListReq, setDeliveryListReq] = useState(null);
    const [deliveryListReqForSearch, setDeliveryListReqForSearch] = useState(null);
    const safeAxiosGet = useSafeAsyncRequest();

    useEffect(() => {
        if (getCookie() !== undefined) {
            getAuthUser();
            getWaiter();
            getCustomer();
            getAdminStaff();
        }
    }, []);

    const getAuthUser = async () => {
        setLoading(true);
        const langUrl = BASE_URL + "/auth/user";
        const data = await safeAxiosGet(langUrl, );
        if (data) {
            if (data[0].is_banned === 0 || data[0].is_banned === "0") {
                setAuthUserInfo({
                    ...authUserInfo,
                    details: data[0],
                    permissions: data[1],
                });

                setLoading(false);
            } else {
                deleteCookie();
            }
        }
    };

    const getSupplier = async () => {
        setLoading(true);
        const supplierUrl = BASE_URL + "/settings/get-supplier";
        const data = await safeAxiosGet(supplierUrl, );
        if (data) {
            setSupplierList(data[0]);
            setSupplierForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedSupplier = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-supplier?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setSupplierList(data[0]);
            setSupplierForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getWaiter = async () => {
        setLoading(true);
        const waiterUrl = BASE_URL + "/settings/get-waiter";
        const data = await safeAxiosGet(waiterUrl, );
        if (data) {
            setWaiterList(data[0]);
            setWaiterForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedWaiter = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-waiter?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setWaiterList(data[0]);
            setWaiterForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getCustomer = async () => {
        setLoading(true);
        const customerUrl = BASE_URL + "/settings/get-customer";
        const data = await safeAxiosGet(customerUrl, );
        if (data) {
            setCustomerList(data[0]);
            setCustomerForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedCustomer = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-customer?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setCustomerList(data[0]);
            setCustomerForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getCustomerOnline = async () => {
        setLoading(true);
        const customerUrl = BASE_URL + "/settings/get-website-customer";
        const data = await safeAxiosGet(customerUrl, );
        if (data) {
            setCustomerListOnline(data[0]);
            setCustomerOnlineForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedCustomerOnline = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-website-customer?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setCustomerListOnline(data[0]);
            setCustomerOnlineForSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getAdminStaff = async () => {
        setLoading(true);
        const adminStaffUrl = BASE_URL + "/settings/get-admin-staff";
        const data = await safeAxiosGet(adminStaffUrl, );
        if (data) {
            setAdminStaffList(data[0]);
            setAdminStaffListforSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedAdminStaff = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-admin-staff?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setAdminStaffList(data[0]);
            setAdminStaffListforSearch(data[1]);
            setDataPaginating(false);
        }
    };

    const getDeliveryUser = async () => {
        setLoading(true);
        const adminStaffUrl = BASE_URL + "/settings/get-delivery-man";
        const data = await safeAxiosGet(adminStaffUrl, );
        if (data) {
            setDeliveryList(data[0]);
            setDeliveryListforSearch(data[1]);
            setDeliveryListReq(data[2]);
            setDeliveryListReqForSearch(data[3]);
            setLoading(false);
        }
    };

    const setPaginatedDeliveryUser = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/get-delivery-man?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setDeliveryList(data[0]);
            setDeliveryListforSearch(data[1]);
            setDeliveryListReq(data[2]);
            setDeliveryListReqForSearch(data[3]);
            setDataPaginating(false);
        }
    };

    return (
        <UserContext.Provider
            value={{
                getAuthUser,
                setAuthUserInfo,
                authUserInfo,
                getSupplier,
                supplierList,
                setSupplierList,
                setPaginatedSupplier,
                supplierForSearch,
                setSupplierForSearch,
                getWaiter,
                waiterList,
                setWaiterList,
                setPaginatedWaiter,
                waiterForSearch,
                setWaiterForSearch,
                getCustomer,
                customerList,
                setCustomerList,
                setPaginatedCustomer,
                customerForSearch,
                setCustomerForSearch,
                getCustomerOnline,
                customerListOnline,
                setCustomerListOnline,
                setPaginatedCustomerOnline,
                customerOnlineForSearch,
                setCustomerOnlineForSearch,
                getAdminStaff,
                adminStaffList,
                setAdminStaffList,
                setPaginatedAdminStaff,
                adminStaffForSearch,
                setAdminStaffListforSearch,
                getDeliveryUser,
                deliveryList,
                setDeliveryList,
                setPaginatedDeliveryUser,
                deliveryForSearch,
                setDeliveryListforSearch,
                deliveryListReq,
                setDeliveryListReq,
                deliveryListReqForSearch,
                setDeliveryListReqForSearch,
                dataPaginating,
                setDataPaginating,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
