import React, {useState, useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
    _t,
    
    modalLoading,
    tableLoading,
    pagination,
    paginationLoading,
    showingData,
    searchedShowingData,
} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";


import {BASE_URL} from "../../../../../BaseUrl";

//3rd party packages
import {Helmet} from "react-helmet";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//context consumer
import {SettingsContext} from "../../../../../contexts/Settings";
import {RestaurantContext} from "../../../../../contexts/Restaurant";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {AsyncPaginate} from "react-select-async-paginate";

const PaymentTypeCrud = () => {
    const {t} = useTranslation();
    const history = useHistory();
    //getting context values here
    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let {
        //paymentType
        paymentTypeList,
        setPaymentTypeList,
        setPaginatedPaymentType,
        paymentTypeForSearch,
        setPaymentTypeforSearch,

        //pagination
        dataPaginating,
    } = useContext(RestaurantContext);

    // States hook here
    //new group
    let [newPaymentType, setNewPaymentType] = useState({
        name: "",
        input_key: "",
        account: "",
        edit: false,
        editSlug: null,
        uploading: false,
    });

    //search result
    let [searchedPaymentType, setSearchedPaymentType] = useState({
        list: null,
        searched: false,
    });

    //useEffect == componentDidMount
    useEffect(() => {
    }, [newPaymentType]);

    //set name, input key hook
    const handleSetNewPaymentType = (e) => {
        if (e.target) {
            setNewPaymentType({...newPaymentType, [e.target.name]: e.target.value});
        } else {
            setNewPaymentType({...newPaymentType, account: e});
        }
    };

    //Save New paymentType
    const handleSaveNewPaymentType = (e) => {
        e.preventDefault();
        setNewPaymentType({
            ...newPaymentType,
            uploading: true,
        });
        const paymentTypeUrl = BASE_URL + `/settings/new-payment-type`;
        let formData = new FormData();
        formData.append("name", newPaymentType.name);
        formData.append("account", newPaymentType.account?.id ?? '');
        formData.append("input_key", newPaymentType.input_key);
        return window.axiosInstance.post(paymentTypeUrl, formData, )
            .then((res) => {
                if (res.data !== "A payment type already exists with this key") {
                    setNewPaymentType({
                        name: "",
                        input_key: "",
                        account: "",
                        edit: false,
                        editSlug: null,
                        uploading: false,
                    });
                    setPaymentTypeList(res.data[0]);
                    setPaymentTypeforSearch(res.data[1]);
                    setSearchedPaymentType({
                        ...searchedPaymentType,
                        list: res.data[1],
                    });
                    setLoading(false);
                    toast.success(`${_t(t("Payment type has been added"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    setLoading(false);
                    setNewPaymentType({
                        ...newPaymentType,
                        uploading: false,
                    });
                    toast.error(
                        `${_t(t("A payment type already exists with this key"))}`,
                        {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        }
                    );
                }
            })
            .catch((e) => {
                setLoading(false);
                setNewPaymentType({
                    ...newPaymentType,
                    uploading: false,
                });
                if (e.response.status === 400) {
                    toast.error(e.response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    toast.error(`${_t(t("Please try again"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                }


            });
    };

    //set edit true & values
    const handleSetEdit = (slug) => {
        let paymentType = paymentTypeForSearch.filter((item) => {
            return item.slug === slug;
        });
        setNewPaymentType({
            ...newPaymentType,
            name: paymentType[0].name,
            input_key: paymentType[0].input_key,
            account: paymentType[0].account_id ? {... paymentType[0].account ,label : paymentType[0].account.bank_name , value : paymentType[0].account.id } : '',
            editSlug: paymentType[0].slug,
            edit: true,
        });
    };

    //update payment Type
    const handleUpdatePaymentType = (e) => {
        e.preventDefault();
        setNewPaymentType({
            ...newPaymentType,
            uploading: true,
        });
        const paymentTypeUrl = BASE_URL + `/settings/update-payment-type`;
        let formData = new FormData();
        formData.append("name", newPaymentType.name);
        formData.append("account", newPaymentType.account?.id ?? '');
        formData.append("editSlug", newPaymentType.editSlug);
        return window.axiosInstance.post(paymentTypeUrl, formData, )
            .then((res) => {
                setNewPaymentType({
                    name: "",
                    input_key: "",
                    account: "",
                    edit: false,
                    editSlug: null,
                    uploading: false,
                });
                setPaymentTypeList(res.data[0]);
                setPaymentTypeforSearch(res.data[1]);
                setSearchedPaymentType({
                    ...searchedPaymentType,
                    list: res.data[1],
                });
                setLoading(false);
                toast.success(`${_t(t("Payment type has been updated"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            })
            .catch((e) => {
                setLoading(false);
                setNewPaymentType({
                    ...newPaymentType,
                    uploading: false,
                });
                if (e.response.status === 400) {
                    toast.error(e.response?.data?.message, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    toast.error(`${_t(t("Please try again"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                }
            });
    };

    //search payment Type here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedPaymentType({...searchedPaymentType, searched: false});
        } else {
            let searchedList = paymentTypeForSearch.filter((item) => {
                let lowerCaseItemName = item.name.toLowerCase();
                let lowerCaseItemInputKey = item.input_key.toLowerCase();
                return (
                    lowerCaseItemName.includes(searchInput) ||
                    lowerCaseItemInputKey.includes(searchInput)
                );
            });
            setSearchedPaymentType({
                ...searchedPaymentType,
                list: searchedList,
                searched: true,
            });
        }
    };

    //delete confirmation modal of paymentType
    const handleDeleteConfirmation = (slug) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">{_t(t("You want to delete this?"))}</p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleDeletePaymentType(slug);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, delete it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    //delete paymentType here
    const handleDeletePaymentType = (slug) => {
        setLoading(true);
        const paymentTypeUrl = BASE_URL + `/settings/delete-payment-type/${slug}`;
        return window.axiosInstance.get(paymentTypeUrl, )
            .then((res) => {
                setPaymentTypeList(res.data[0]);
                setPaymentTypeforSearch(res.data[1]);
                setSearchedPaymentType({
                    ...searchedPaymentType,
                    list: res.data[1],
                });
                setLoading(false);
                toast.success(
                    `${_t(t("Payment type has been deleted successfully"))}`,
                    {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    }
                );
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    const loadOptionsCategories = async (search, loadedOptions, {page}) => {
        let AccountUrl = BASE_URL + `/settings/accounts?page=${page}&perPage=10`;
        if (search.length) {
            AccountUrl += '&term=' + search;
        }
        let res = await window.axiosInstance.get(AccountUrl)
        return {
            options: res.data?.data?.map(item => {
                return {
                    value: item.id,
                    id: item.id,
                    label: item.bankName,
                    availableBalance: item.availableBalance,
                    accountNumber: item.accountNumber
                }
            }),
            hasMore: res?.data?.meta.last_page > res?.data?.meta.current_page,
            additional: {
                page: page + 1,
            },
        };
    }
    const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
        const bottomBorder = (scrollHeight - clientHeight) / 2;
        return bottomBorder < scrollTop;
    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Payment Types"))}</title>
            </Helmet>

            {/* Add modal */}
            <div className="modal fade" id="addPaymentType" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {!newPaymentType.edit
                                        ? _t(t("Add new payment type"))
                                        : _t(t("Update payment type"))}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {/* show form or show saving loading */}
                            {newPaymentType.uploading === false ? (
                                <div key="fragment-payment-type-1">
                                    <form
                                        onSubmit={
                                            !newPaymentType.edit
                                                ? handleSaveNewPaymentType
                                                : handleUpdatePaymentType
                                        }
                                    >
                                        <div>
                                            <label htmlFor="name" className="form-label">
                                                {_t(t("Name"))}{" "}
                                                <small className="text-primary">*</small>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                placeholder="e.g. Cash"
                                                value={newPaymentType.name || ""}
                                                required
                                                onChange={handleSetNewPaymentType}
                                            />
                                        </div>

                                        <div className="mt-3">
                                            <label htmlFor="input_key" className="form-label">
                                                {_t(t("Unique Key"))}{" "}
                                                <small className="text-primary">*</small>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="input_key"
                                                name="input_key"
                                                placeholder="e.g. 'cash-payment' for 'Cash'"
                                                value={newPaymentType.input_key || ""}
                                                required={!newPaymentType.edit}
                                                onChange={handleSetNewPaymentType}
                                                disabled={newPaymentType.edit}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="input_key" className="form-label">
                                                {_t(t("Account"))}{" "}
                                                <small className="text-primary">*</small>
                                            </label>
                                            <AsyncPaginate
                                                placeholder={_t(t("Account"))}
                                                value={newPaymentType.account || ""}
                                                isClearable
                                                defaultValue={''}
                                                loadOptions={loadOptionsCategories}
                                                shouldLoadMore={shouldLoadMore}
                                                onChange={handleSetNewPaymentType}
                                                additional={{
                                                    page: 1,
                                                }}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <div className="row">
                                                <div className="col-6">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                    >
                                                        {!newPaymentType.edit
                                                            ? _t(t("Save"))
                                                            : _t(t("Update"))}
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                        data-dismiss="modal"
                                                    >
                                                        {_t(t("Close"))}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <div key="fragment2">
                                    <div className="text-center text-primary font-weight-bold text-uppercase">
                                        {_t(t("Please wait"))}
                                    </div>
                                    {modalLoading(3)}
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    {!newPaymentType.edit
                                                        ? _t(t("Save"))
                                                        : _t(t("Update"))}
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                    data-dismiss="modal"
                                                >
                                                    {_t(t("Close"))}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Add modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar/>
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                        {newPaymentType.uploading === true || loading === true ? (
                                            tableLoading()
                                        ) : (
                                            <div key="fragment3">
                                                {/* next page data spin loading */}
                                                <div className={`${dataPaginating && "loading"}`}></div>
                                                {/* spin loading ends */}

                                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                        <ul className="t-list fk-breadcrumb">
                                                            <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedPaymentType.searched
                                      ? _t(t("Payment Type List"))
                                      : _t(t("Search Result"))}
                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 col-lg-7">
                                                        <div className="row gx-3 align-items-center">
                                                            {/* Search group */}
                                                            <div className="col-md-9 t-mb-15 mb-md-0">
                                                                <div className="input-group">
                                                                    <div className="form-file">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control border-0 form-control--light-1 rounded-0"
                                                                            placeholder={_t(t("Search")) + ".."}
                                                                            onChange={handleSearch}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="button"
                                                                    >
                                                                        <i
                                                                            className="fa fa-search"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {/* Add group modal trigger button */}
                                                            <div className="col-md-3 text-md-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                                                    data-toggle="modal"
                                                                    data-target="#addPaymentType"
                                                                    onClick={() => {
                                                                        setNewPaymentType({
                                                                            ...newPaymentType,
                                                                            edit: false,
                                                                            uploading: false,
                                                                        });
                                                                    }}
                                                                >
                                                                    {_t(t("add new"))}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Table */}
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-hover min-table-height">
                                                        <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("S/L"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Name"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Unique Key"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Account"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Action"))}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {!searchedPaymentType.searched
                                                            ? [
                                                                paymentTypeList && [
                                                                    paymentTypeList?.data?.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="6"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        paymentTypeList?.data?.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="align-middle"
                                                                                        key={index}
                                                                                    >
                                                                                        <th
                                                                                            scope="row"
                                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                                        >
                                                                                            {index +
                                                                                                1 +
                                                                                                (paymentTypeList.current_page -
                                                                                                    1) *
                                                                                                paymentTypeList.per_page}
                                                                                        </th>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {item.name}
                                                                                        </td>

                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.input_key}
                                                                                        </td>
                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.account_id  ? `${item.account?.bank_name} [${item.account?.account_number}]` : ''}
                                                                                        </td>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {item.input_key !== "cash" ? (
                                                                                                <div className="dropdown">
                                                                                                    <button
                                                                                                        className="btn t-bg-clear t-text-dark--light-40"
                                                                                                        type="button"
                                                                                                        data-toggle="dropdown"
                                                                                                    >
                                                                                                        <i className="fa fa-ellipsis-h"></i>
                                                                                                    </button>
                                                                                                    <div
                                                                                                        className="dropdown-menu">
                                                                                                        <button
                                                                                                            className="dropdown-item sm-text text-capitalize"
                                                                                                            onClick={() =>
                                                                                                                handleSetEdit(
                                                                                                                    item.slug
                                                                                                                )
                                                                                                            }
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#addPaymentType"
                                                                                                        >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                            {_t(t("Edit"))}
                                                                                                        </button>

                                                                                                        <button
                                                                                                            className="dropdown-item sm-text text-capitalize"
                                                                                                            onClick={() => {
                                                                                                                handleDeleteConfirmation(
                                                                                                                    item.slug
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                                                                            {_t(t("Delete"))}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                _t(
                                                                                                    t(
                                                                                                        "Edit or Delete is not allowed for"
                                                                                                    )
                                                                                                ) +
                                                                                                [
                                                                                                    " " +
                                                                                                    item.name,
                                                                                                ]
                                                                                            )}                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    ),
                                                                ],
                                                            ]
                                                            : [
                                                                /* searched data, logic === haveData*/
                                                                searchedPaymentType && [
                                                                    searchedPaymentType.list.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="6"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        searchedPaymentType.list.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <tr
                                                                                        className="align-middle"
                                                                                        key={index}
                                                                                    >
                                                                                        <th
                                                                                            scope="row"
                                                                                            className="xsm-text text-capitalize align-middle text-center"
                                                                                        >
                                                                                            {index +
                                                                                                1 +
                                                                                                (paymentTypeList.current_page -
                                                                                                    1) *
                                                                                                paymentTypeList.per_page}
                                                                                        </th>

                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {item.name}
                                                                                        </td>

                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.input_key}
                                                                                        </td>
                                                                                        <td className="xsm-text align-middle text-center">
                                                                                            {item.account_id  ? `${item.account?.bank_name} [${item.account?.account_number}]` : ''}


                                                                                        </td>
                                                                                        <td className="xsm-text text-capitalize align-middle text-center">
                                                                                            {item.input_key !== "cash" ? (
                                                                                                <div className="dropdown">
                                                                                                    <button
                                                                                                        className="btn t-bg-clear t-text-dark--light-40"
                                                                                                        type="button"
                                                                                                        data-toggle="dropdown"
                                                                                                    >
                                                                                                        <i className="fa fa-ellipsis-h"></i>
                                                                                                    </button>
                                                                                                    <div
                                                                                                        className="dropdown-menu">
                                                                                                        <button
                                                                                                            className="dropdown-item sm-text text-capitalize"
                                                                                                            onClick={() =>
                                                                                                                handleSetEdit(
                                                                                                                    item.slug
                                                                                                                )
                                                                                                            }
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#addPaymentType"
                                                                                                        >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                            {_t(t("Edit"))}
                                                                                                        </button>

                                                                                                        <button
                                                                                                            className="dropdown-item sm-text text-capitalize"
                                                                                                            onClick={() => {
                                                                                                                handleDeleteConfirmation(
                                                                                                                    item.slug
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                                                                            {_t(t("Delete"))}
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                _t(
                                                                                                    t(
                                                                                                        "Edit or Delete is not allowed for"
                                                                                                    )
                                                                                                ) +
                                                                                                [
                                                                                                    " " +
                                                                                                    item.name,
                                                                                                ]
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    ),
                                                                ],
                                                            ]}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* pagination loading effect */}
                            {newPaymentType.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedPaymentType.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(
                                                            paymentTypeList,
                                                            setPaginatedPaymentType
                                                        )}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(paymentTypeList)}
                                  </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() =>
                                                                    setSearchedPaymentType({
                                                                        ...searchedPaymentType,
                                                                        searched: false,
                                                                    })
                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                      searchedPaymentType,
                                      paymentTypeForSearch
                                  )}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */}
        </>
    );
};

export default PaymentTypeCrud;
