import React, {useContext, useEffect} from "react";
import {SettingsContext} from "../../contexts/Settings";
import {getSystemSettings} from "../../functions/Functions";
import {useTranslation} from "react-i18next";
import {_t} from "../../functions/Functions";

const CompanyInfo = (props) => {
    const { t } = useTranslation();

    let {generalSettings} = useContext(
        SettingsContext
    );
    return (
        <>
            <div className="company-logo">
                <img src={getSystemSettings(generalSettings, "type_logo")}
                     alt={getSystemSettings(generalSettings, "siteName")} className="lg-logo"/>
                <address className="mt-2">
                    <strong>{getSystemSettings(generalSettings, "siteName")}</strong><br/>
                    <strong>{_t(t("phone"))}: </strong>
                    {getSystemSettings(generalSettings, "phnNo")}
                    {getSystemSettings(generalSettings, "email") ?
                        <>
                            <br/>
                            <strong>{_t(t("email"))}: </strong>
                            {getSystemSettings(generalSettings, "email")}
                        </> : ""}
                    <br/>
                    <strong>{_t(t("address"))}: </strong>
                    {getSystemSettings(generalSettings, "address")}
                </address>
            </div>
        </>);
};

export default CompanyInfo;
