import React, {useContext, useState} from "react";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import moment from "moment";

//functions
import {
    _t, currencySymbolLeft, currencySymbolRight, formatPrice,
    
    modalLoading,
    pagination,
    paginationLoading,
    showingData,
    tableLoading,
} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";

//3rd party packages
import {Helmet} from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import {SettingsContext} from "../../../../../contexts/Settings";
import {confirmAlert} from "react-confirm-alert";
import {BASE_URL} from "../../../../../BaseUrl";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {AsyncPaginate} from "react-select-async-paginate";


const BalanceList = () => {
    const {t} = useTranslation();
    const [search, setSearch] = useState('')
    const [BalanceList, setBalanceList] = useState({})
    const [dataPaginating, setDataPaginating] = useState(false)
    const [account, setAccount] = useState('')
    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let [newAccount, setNewAccount] = useState({
        date: new Date().toISOString().slice(0, 10),
        edit: false,
        editSlug: null,
        uploading: false,
    });
    let [searchedAccount, setSearchedAccount] = useState({
        list: null,
        searched: false,
    });

    //search Account here
    const handleSearch = (e = null) => {
        let searchInput = e ? e.target.value.toLowerCase() : '';
        setSearch(searchInput)
        setSearchedAccount({...searchedAccount, searched: searchInput.length !== 0});
        setLoading(true);
        let AccountUrl = BASE_URL + "/settings/balances?page=1&perPage=10";
        if (searchInput.length) {
            AccountUrl += '&term=' + searchInput;
        }
        return window.axiosInstance.get(AccountUrl, )
            .then((res) => {
                setBalanceList(res.data)
                setLoading(false);
            });
    };

    //delete confirmation modal of Account
    const handleDeleteConfirmation = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">{_t(t("You want to delete this?"))}</p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleDeleteAccount(item);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, delete it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    const handleDeleteAccount = (item) => {
        setLoading(true);
        const AccountUrl =
            BASE_URL + `/settings/balances/${item.slug}`;
        return window.axiosInstance.delete(AccountUrl, )
            .then((res) => {
                // setBalanceList(res.data);
                // setSearchedAccount({
                //     ...searchedAccount,
                //     list: res.data,
                // });
                setLoading(false);
                toast.success(
                    `${_t(t("Transaction deleted successfully"))}`,
                    {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    }
                );
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };
    const handleSetEdit = (item) => {
        let Balance = BalanceList.data.find((item) => item.id === item.id);
        let aa = Balance.account
        setValue('account', {
            value: aa.id,
            id: aa.id,
            label: aa.bankName,
            availableBalance: aa.availableBalance,
            accountNumber: aa.accountNumber
        })
        setValue('available_balance', aa.availableBalance)
        setValue('type', Balance.type)
        setValue('amount', Balance.amount)
        setValue('date', Balance.transactionDate)
        setValue('status', Balance.status)
        setValue('note', Balance.note)
        setValue('slug', Balance.slug)
        setAccount(true)
        setNewAccount({
            ...newAccount,
            editSlug: Balance.slug,
            edit: true,
        });
    };
    const setPaginatedAccount = (pageNo) => {
        setDataPaginating(true);
        let url = BASE_URL + `/settings/balances?page=${pageNo}&perPage=10`;
        if (search.length) {
            url += '&term=' + search;
        }
        return window.axiosInstance.get(url, )
            .then((res) => {
                setBalanceList(res.data);
                setDataPaginating(false);
            })
            .catch(() => {
            });
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        control,
        formState: {errors}
    } = useForm({defaultValues: {account: ""}});
    const onSubmit = data => {
        const accountUrl = newAccount.edit ? BASE_URL + "/settings/balances/" + data.slug : BASE_URL + "/settings/balances";
        setNewAccount({
            ...newAccount,
            uploading: true,
        });
        data.account = {accountNumber: data.account.accountNumber, id: data.account.id};
        let action = newAccount.edit ? 'put' : 'post';
        return window.axiosInstance[action](accountUrl, data, )
            .then((res) => {
                setLoading(false);
                toast.success(!newAccount.edit ? `${_t(t("Balance added successfully!"))}` : `${_t(t("Balance updated successfully!"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                setNewAccount({
                    name: "",
                    edit: false,
                    editSlug: null,
                    uploading: false,
                });
                reset({
                    account: '',
                    type: 1,
                    amount: '',
                    date: new Date().toISOString().slice(0, 10),
                    status: 1,
                    note: '',
                })
                setAccount(false)

            })
            .catch((error) => {
                setLoading(false);
                setNewAccount({
                    ...newAccount,
                    uploading: false,
                });
                if (error.response.data.errors) {
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach((item) => {
                            toast.error(
                                `${error.response.data.errors[item]}`,
                                {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                }
                            );
                        })
                    } else {
                        toast.error(`${_t(t("Please try again"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                    }
                }
            });
    };

    const loadOptionsCategories = async (search, loadedOptions, {page}) => {
        let AccountUrl = BASE_URL + `/settings/accounts?page=${page}&perPage=10`;
        if (search.length) {
            AccountUrl += '&term=' + search;
        }
        let res = await window.axiosInstance.get(AccountUrl)
        return {
            options: res.data?.data?.map(item => {
                return {
                    value: item.id,
                    id: item.id,
                    label: item.bankName,
                    availableBalance: item.availableBalance,
                    accountNumber: item.accountNumber
                }
            }),
            hasMore: res?.data?.meta.last_page > res?.data?.meta.current_page,
            additional: {
                page: page + 1,
            },
        };
    }
    const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
        const bottomBorder = (scrollHeight - clientHeight) / 2;
        return bottomBorder < scrollTop;
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Balance Adjustments"))}</title>
            </Helmet>

            {/* Add modal */}
            <div className="modal fade" id="addAccount" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {!newAccount.edit
                                        ? _t(t("create_adjustment"))
                                        : _t(t("Update_adjustment"))}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {newAccount.uploading === false ? (

                                <form onSubmit={handleSubmit(onSubmit)} id="accountForm">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="bankName">{_t(t("Account"))} <span
                                                            className="text-danger">*</span></label>
                                                        <Controller
                                                            control={control}
                                                            name="account"
                                                            rules={{required: true}}
                                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                                <AsyncPaginate
                                                                    placeholder={_t(t("Account"))}
                                                                    value={value}
                                                                    isClearable
                                                                    defaultValue={''}
                                                                    onBlur={onBlur}
                                                                    loadOptions={loadOptionsCategories}
                                                                    shouldLoadMore={shouldLoadMore}
                                                                    onChange={(data) => {
                                                                        onChange(data)
                                                                        setValue("available_balance", data?.availableBalance ?? 0)
                                                                        setAccount(!!data?.value)
                                                                    }}
                                                                    additional={{
                                                                        page: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.account && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="status"> {_t(t("Type"))}</label>
                                                        <select
                                                            id="type"
                                                            name="type"
                                                            className="form-control"
                                                            defaultValue={1}
                                                            {...register("type", {})}
                                                        >
                                                            <option value="1">{_t(t("add_balance"))}</option>
                                                            <option value="0">{_t(t("remove_balance"))}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {account ?
                                                    <div className="row mb-3">
                                                        <div className="form-group col-md-6">
                                                            <label
                                                                htmlFor="available_balance"> {_t(t("available_balance"))}</label>
                                                            <input
                                                                id="available_balance"
                                                                type="text" readOnly="true"
                                                                name="available_balance"
                                                                value={getValues('available_balance')}
                                                                {...register("available_balance", {})}
                                                                className="form-control"
                                                                placeholder={_t(t("available_balance"))}
                                                            />

                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="amount"> {_t(t("Amount"))} <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                id="amount"
                                                                type="number"
                                                                {...register("amount", {required: true})}
                                                                className="form-control"
                                                                name="amount"
                                                                placeholder={_t(t("amount_placeholder"))}
                                                            />
                                                            {errors.amount && <span
                                                                className="text-danger">{_t(t("This field is required"))}</span>}

                                                        </div>
                                                    </div> : ''}
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="date"> {_t(t("date"))}</label>
                                                        <input
                                                            id="date"
                                                            type="date"
                                                            defaultValue={newAccount.date || ""}
                                                            {...register("date", {})}
                                                            className="form-control"
                                                            name="date"
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="status"> {_t(t("status"))}</label>
                                                        <select
                                                            id="status"
                                                            name="status"
                                                            className="form-control"
                                                            defaultValue={1}
                                                            {...register("status", {})}
                                                        >
                                                            <option value="1">{_t(t("active"))}</option>
                                                            <option value="0">{_t(t("in_active"))}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="status"> {_t(t("note"))}</label>
                                                    <textarea
                                                        id="note"
                                                        name="note"
                                                        {...register("note", {})}
                                                        className="form-control"
                                                        placeholder={_t(t("note_placeholder"))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                >
                                                    {!newAccount.edit
                                                        ? _t(t("Save"))
                                                        : _t(t("Update"))}
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                    data-dismiss="modal"
                                                >
                                                    {_t(t("Close"))}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <div key="fragment2">
                                    <div className="text-center text-primary font-weight-bold text-uppercase">
                                        {_t(t("Please wait"))}
                                    </div>
                                    {modalLoading(3)}
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            {/* Add modal Ends*/
            }

            {/* main body */
            }
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar/>
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                        <div key="fragment3">
                                            {/* next page data spin loading */}
                                            <div className={`${(dataPaginating || loading) && "loading"}`}></div>
                                            {/* spin loading ends */}
                                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                    <ul className="t-list fk-breadcrumb">
                                                        <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedAccount.searched
                                      ? _t(t("Balance Adjustment List"))
                                      : _t(t("Search Result"))}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 col-lg-7">
                                                    <div className="row gx-3 align-items-center">
                                                        {/* Search group */}
                                                        <div className="col-md-9 t-mb-15 mb-md-0">
                                                            <div className="input-group">
                                                                <div className="form-file">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 form-control--light-1 rounded-0"
                                                                        placeholder={_t(t("Search")) + ".."}
                                                                        onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="button">
                                                                    <i className="fa fa-search"
                                                                       aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {/* Add group modal trigger button */}
                                                        <div className="col-md-3 text-md-right">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                                                data-toggle="modal"
                                                                data-target="#addAccount"
                                                                onClick={() => {
                                                                    setNewAccount({
                                                                        ...newAccount,
                                                                        edit: false,
                                                                        uploading: false,
                                                                    });
                                                                    reset({
                                                                        account: '',
                                                                        type: 1,
                                                                        amount: '',
                                                                        date: new Date().toISOString().slice(0, 10),
                                                                        status: 1,
                                                                        note: '',
                                                                    })
                                                                }}
                                                            >
                                                                {_t(t("add new"))}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Table */}
                                            {newAccount.uploading === true || loading === true ? (
                                                tableLoading()
                                            ) : (
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-hover min-table-height">
                                                        <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                #
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Bank Name"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Account Number"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Amount"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Type"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Date"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Status"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border">
                                                                {_t(t("Action"))}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {BalanceList && [
                                                            BalanceList?.data?.length === 0 ? (
                                                                <tr className="align-middle">
                                                                    <td
                                                                        scope="row"
                                                                        colSpan="9"
                                                                        className="xsm-text align-middle text-center"
                                                                    >
                                                                        {_t(t("No data available"))}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                BalanceList?.data?.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <tr
                                                                                className="align-middle"
                                                                                key={index}
                                                                            >
                                                                                <th
                                                                                    scope="row"
                                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                                >
                                                                                    {index +
                                                                                        1 +
                                                                                        (BalanceList.meta.current_page -
                                                                                            1) *
                                                                                        BalanceList.meta.per_page}
                                                                                </th>

                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.account.bankName}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.account.accountNumber}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {currencySymbolLeft()}
                                                                                    {formatPrice(item.amount)}
                                                                                    {currencySymbolRight()}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.type === 1 ?
                                                                                        <span
                                                                                            className="badge bg-success">{_t(t("add_balance"))}</span>
                                                                                        :
                                                                                        <span
                                                                                            className="badge bg-danger">{_t(t("remove_balance"))}</span>
                                                                                    }
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {moment(item.transactionDate).format("Do MMM, YYYY")}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.status === 1 ?
                                                                                        <span
                                                                                            className="badge bg-success">{_t(t("active"))}</span>
                                                                                        :
                                                                                        <span
                                                                                            className="badge bg-danger">{_t(t("in_active"))}</span>
                                                                                    }
                                                                                </td>


                                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                                    <div className="dropdown">
                                                                                        <button
                                                                                            className="btn t-bg-clear t-text-dark--light-40"
                                                                                            type="button"
                                                                                            data-toggle="dropdown"
                                                                                        >
                                                                                            <i className="fa fa-ellipsis-h"></i>
                                                                                        </button>
                                                                                        <div
                                                                                            className="dropdown-menu">
                                                                                            <button
                                                                                                className="dropdown-item sm-text text-capitalize"
                                                                                                onClick={() =>
                                                                                                    handleSetEdit(
                                                                                                        item
                                                                                                    )
                                                                                                }
                                                                                                data-toggle="modal"
                                                                                                data-target="#addAccount"
                                                                                            >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                {_t(t("Edit"))}
                                                                                            </button>

                                                                                            <button
                                                                                                className="dropdown-item sm-text text-capitalize"
                                                                                                onClick={() => {
                                                                                                    handleDeleteConfirmation(
                                                                                                        item
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                                                                {_t(t("Delete"))}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            ),
                                                        ]}
                                                        </tbody>
                                                    </table>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* pagination loading effect */}
                            {newAccount.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedAccount.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(
                                                            BalanceList.meta,
                                                            setPaginatedAccount
                                                        )}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(BalanceList.meta)}
                                  </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => {
                                                                    setSearchedAccount({
                                                                        ...searchedAccount,
                                                                        searched: false,
                                                                    })
                                                                    setSearch('');
                                                                    handleSearch()
                                                                }

                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(
                                      BalanceList
                                  )}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */
            }
        </>
    )
        ;
};

export default BalanceList;
