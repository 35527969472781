import axios from 'axios';
import { BASE_URL } from "./BaseUrl";
import { getCookie } from "./functions/Functions";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

axiosInstance.interceptors.request.use(config => {
    const token = getCookie();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    const language = localStorage.getItem('language');
    if (language) {
        config.headers['Accept-Language'] = language;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;
