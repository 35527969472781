import React, {useState, useContext, useEffect} from "react";
import {NavLink, useHistory} from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import moment from "moment";

//functions
import
{
    _t,
    
    modalLoading,
    tableLoading,
    pagination,
    paginationLoading,
    showingData,
    searchedShowingData, currencySymbolLeft, formatPrice, currencySymbolRight,
} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";

//3rd party packages
import {Helmet} from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import {SettingsContext} from "../../../../../contexts/Settings";
import {confirmAlert} from "react-confirm-alert";
import {BASE_URL} from "../../../../../BaseUrl";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {AsyncPaginate} from "react-select-async-paginate";


const SubCategoryList = () => {
    const {t} = useTranslation();
    const [search, setSearch] = useState('')
    const [ExpenseSubCategoryList, setExpenseSubCategoryList] = useState({})
    const [dataPaginating, setDataPaginating] = useState(false)
    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let [newExpenseSubCategory, setNewExpenseSubCategory] = useState({
        date: new Date().toISOString().slice(0, 10),
        edit: false,
        editSlug: null,
        uploading: false,
    });
    let [expenseSubCategory, setExpenseSubCategory] = useState({
        list: null,
        searched: false,
    });

    //search ExpenseSubCategory here
    const handleSearch = (e = null) => {
        let searchInput = e ? e.target.value.toLowerCase() : '';
        setSearch(searchInput)
        setExpenseSubCategory({...expenseSubCategory, searched: searchInput.length !== 0});
        setLoading(true);
        let url = BASE_URL + "/settings/expense-sub-categories?page=1&perPage=10";
        if (searchInput.length) {
            url += '&term=' + searchInput;
        }
        return window.axiosInstance.get(url, )
            .then((res) => {
                setExpenseSubCategoryList(res.data)
                setLoading(false);
            });
    };

    //delete confirmation modal of ExpenseSubCategory
    const handleDeleteConfirmation = (item) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className="card card-body">
                        <h1>{_t(t("Are you sure?"))}</h1>
                        <p className="text-center">{_t(t("You want to delete this?"))}</p>
                        <div className="d-flex justify-content-center">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    handleDeleteSubCategories(item);
                                    onClose();
                                }}
                            >
                                {_t(t("Yes, delete it!"))}
                            </button>
                            <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                                {_t(t("No"))}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    const handleDeleteSubCategories = (item) => {
        setLoading(true);
        const url =
            BASE_URL + `/settings/expense-sub-categories/${item.slug}`;
        return window.axiosInstance.delete(url, )
            .then((res) => {
                // setExpenseSubCategoryList(res.data);
                // setExpenseSubCategory({
                //     ...expenseSubCategory,
                //     list: res.data,
                // });
                setLoading(false);
                toast.success(
                    `${_t(t("ExpenseSubCategory has been deleted successfully"))}`,
                    {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    }
                );
            })
            .catch(() => {
                setLoading(false);
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };
    const handleSetEdit = (item) => {
        let ExpenseSubCategory = ExpenseSubCategoryList.data.find((item) => item.id === item.id);
        setValue('name', ExpenseSubCategory.name)
        setValue('status', ExpenseSubCategory.status)
        setValue('note', ExpenseSubCategory.note)
        setValue('slug', ExpenseSubCategory.slug)
        setValue('category', {
            value: item.category.id,
            id: item.category.id,
            label: item.category.name
        })
        setNewExpenseSubCategory({
            ...newExpenseSubCategory,
            editSlug: ExpenseSubCategory.slug,
            edit: true,
        });
    };
    const setPaginatedExpenseSubCategory = (pageNo) => {
        setDataPaginating(true);
        let url = BASE_URL + `/settings/expense-sub-categories?page=${pageNo}&perPage=10`;
        if (search.length) {
            url += '&term=' + search;
        }
        return window.axiosInstance.get(url, )
            .then((res) => {
                setExpenseSubCategoryList(res.data);
                setDataPaginating(false);
            })
            .catch(() => {
            });
    };


    const {register, handleSubmit, reset, setValue,getValues, control, formState: {errors}} = useForm();
    const onSubmit = data => {
        const url = newExpenseSubCategory.edit ? BASE_URL + "/settings/expense-sub-categories/" + data.slug : BASE_URL + "/settings/expense-sub-categories";
        setNewExpenseSubCategory({
            ...newExpenseSubCategory,
            uploading: true,
        });
        let action = newExpenseSubCategory.edit ? 'put' : 'post';
        return window.axiosInstance[action](url, data, )
            .then((res) => {
                setLoading(false);
                toast.success(!newExpenseSubCategory.edit ? `${_t(t("SubCategory has been added"))}` : `${_t(t("SubCategory updated successfully"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                setNewExpenseSubCategory({
                    name: "",
                    edit: false,
                    editSlug: null,
                    uploading: false,
                });
                reset({
                    name: '',
                    status: 1,
                    note: '',
                })
            })
            .catch((error) => {
                setLoading(false);
                setNewExpenseSubCategory({
                    ...newExpenseSubCategory,
                    uploading: false,
                });
                if (error.response.data.errors) {
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach((item) => {
                            toast.error(
                                `${error.response.data.errors[item]}`,
                                {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                }
                            );
                        })
                    } else {
                        toast.error(`${_t(t("Please try again"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                    }
                }
            });
    };
    const loadOptionsCategories = async (search, loadedOptions, {page}) => {
        let url = BASE_URL + `/settings/expense-categories?page=${page}&perPage=10`;
        if (search.length) {
            url += '&term=' + search;
        }
        let res = await window.axiosInstance.get(url)
        return {
            options: res.data?.data?.map(item => {
                return {
                    value: item.id,
                    id: item.id,
                    label: item.name,
                }
            }),
            hasMore: res?.data?.meta.last_page > res?.data?.meta.current_page,
            additional: {
                page: page + 1,
            },
        };
    }
    const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
        const bottomBorder = (scrollHeight - clientHeight) / 2;
        return bottomBorder < scrollTop;
    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Expense Sub Categories"))}</title>
            </Helmet>

            {/* Add modal */}
            <div className="modal fade" id="addSubCategories" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {!newExpenseSubCategory.edit
                                        ? _t(t("create_expense_SubCategory"))
                                        : _t(t("Update_expense_SubCategory"))}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {newExpenseSubCategory.uploading === false ? (

                                <form onSubmit={handleSubmit(onSubmit)} id="SubCategoriesForm">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="name">{_t(t("name"))} <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            type="text"
                                                            {...register("name", {required: true})}
                                                            className="form-control" placeholder={_t(t("name"))}
                                                        />
                                                        {errors.name && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="Category">{_t(t("Category"))} <span
                                                            className="text-danger">*</span></label>
                                                        <Controller
                                                            control={control}
                                                            name="category"
                                                            rules={{required: true}}
                                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                                <AsyncPaginate
                                                                    placeholder={_t(t("Category_placeholder"))}
                                                                    value={value}
                                                                    isClearable
                                                                    defaultValue={''}
                                                                    onBlur={onBlur}
                                                                    loadOptions={loadOptionsCategories}
                                                                    shouldLoadMore={shouldLoadMore}
                                                                    onChange={(data) => {
                                                                        onChange(data)
                                                                    }}
                                                                    additional={{
                                                                        page: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.category && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="status"> {_t(t("status"))}</label>
                                                        <select
                                                            id="status"
                                                            name="status"
                                                            className="form-control"
                                                            defaultValue={1}
                                                            {...register("status", {})}
                                                        >
                                                            <option value="1">{_t(t("active"))}</option>
                                                            <option value="0">{_t(t("in_active"))}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="status"> {_t(t("note"))}</label>
                                                    <textarea
                                                        id="note"
                                                        name="note"
                                                        {...register("note", {})}
                                                        className="form-control"
                                                        placeholder={_t(t("note_placeholder"))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                >
                                                    {!newExpenseSubCategory.edit
                                                        ? _t(t("Save"))
                                                        : _t(t("Update"))}
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                    data-dismiss="modal"
                                                >
                                                    {_t(t("Close"))}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <div key="fragment2">
                                    <div className="text-center text-primary font-weight-bold text-uppercase">
                                        {_t(t("Please wait"))}
                                    </div>
                                    {modalLoading(3)}
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            {/* Add modal Ends*/
            }

            {/* main body */
            }
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar/>
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                            <div key="fragment3">
                                                {/* next page data spin loading */}
                                                <div className={`${(dataPaginating || loading) && "loading"}`}></div>
                                                {/* spin loading ends */}
                                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                    <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                                        <ul className="t-list fk-breadcrumb">
                                                            <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!expenseSubCategory.searched
                                      ? _t(t("Expense Sub Categories"))
                                      : _t(t("Search Result"))}
                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6 col-lg-7">
                                                        <div className="row gx-3 align-items-center">
                                                            {/* Search group */}
                                                            <div className="col-md-9 t-mb-15 mb-md-0">
                                                                <div className="input-group">
                                                                    <div className="form-file">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control border-0 form-control--light-1 rounded-0"
                                                                            placeholder={_t(t("Search")) + ".."}
                                                                            onChange={handleSearch}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="button">
                                                                        <i className="fa fa-search"
                                                                           aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {/* Add group modal trigger button */}
                                                            <div className="col-md-3 text-md-right">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                                                    data-toggle="modal"
                                                                    data-target="#addSubCategories"
                                                                    onClick={() => {
                                                                        setNewExpenseSubCategory({
                                                                            ...newExpenseSubCategory,
                                                                            edit: false,
                                                                            uploading: false,
                                                                        });
                                                                        reset({
                                                                            name: '',
                                                                            status: 1,
                                                                            note: '',
                                                                        })
                                                                    }}
                                                                >
                                                                    {_t(t("add new"))}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Table */}
                                                {newExpenseSubCategory.uploading === true || loading === true ? (
                                                    tableLoading()
                                                ) : (
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-hover min-table-height">
                                                        <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                #
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("category"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("sub_category_code"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("sub_category_name"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Status"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Action"))}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {ExpenseSubCategoryList && [
                                                            ExpenseSubCategoryList?.data?.length === 0 ? (
                                                                <tr className="align-middle">
                                                                    <td
                                                                        scope="row"
                                                                        colSpan="6"
                                                                        className="xsm-text align-middle text-center"
                                                                    >
                                                                        {_t(t("No data available"))}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ExpenseSubCategoryList?.data?.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <tr
                                                                                className="align-middle"
                                                                                key={index}
                                                                            >
                                                                                <th
                                                                                    scope="row"
                                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                                >
                                                                                    {index +
                                                                                        1 +
                                                                                        (ExpenseSubCategoryList.meta.current_page -
                                                                                            1) *
                                                                                        ExpenseSubCategoryList.meta.per_page}
                                                                                </th>

                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.category.name} [{item.category.code}]
                                                                                </td>  
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.code}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.name}

                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.status === 1 ?
                                                                                        <span className="badge bg-success">{_t(t("active"))}</span>
                                                                                        :
                                                                                        <span className="badge bg-danger">{_t(t("in_active"))}</span>
                                                                                    }
                                                                                </td>
                                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                                    <div className="dropdown">
                                                                                        <button
                                                                                            className="btn t-bg-clear t-text-dark--light-40"
                                                                                            type="button"
                                                                                            data-toggle="dropdown"
                                                                                        >
                                                                                            <i className="fa fa-ellipsis-h"></i>
                                                                                        </button>
                                                                                        <div
                                                                                            className="dropdown-menu">
                                                                                            <button
                                                                                                className="dropdown-item sm-text text-capitalize"
                                                                                                onClick={() =>
                                                                                                    handleSetEdit(
                                                                                                        item
                                                                                                    )
                                                                                                }
                                                                                                data-toggle="modal"
                                                                                                data-target="#addSubCategories"
                                                                                            >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                {_t(t("Edit"))}
                                                                                            </button>

                                                                                            <button
                                                                                                className="dropdown-item sm-text text-capitalize"
                                                                                                onClick={() => {
                                                                                                    handleDeleteConfirmation(
                                                                                                        item
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-trash"></i>
                                                        </span>
                                                                                                {_t(t("Delete"))}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            ),
                                                        ]}
                                                        </tbody>
                                                    </table>
                                                </div>)}
                                            </div>
                                    </div>
                                </div>
                            </div>

                            {/* pagination loading effect */}
                            {newExpenseSubCategory.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !expenseSubCategory.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(
                                                            ExpenseSubCategoryList.meta,
                                                            setPaginatedExpenseSubCategory
                                                        )}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(ExpenseSubCategoryList.meta)}
                                  </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => {
                                                                    setExpenseSubCategory({
                                                                        ...expenseSubCategory,
                                                                        searched: false,
                                                                    })
                                                                    setSearch('');
                                                                    handleSearch()
                                                                }

                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(
                                      ExpenseSubCategoryList
                                  )}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */
            }
        </>
    )
        ;
};

export default SubCategoryList;
