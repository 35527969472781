import React, { useState, useEffect, createContext } from 'react';
import { BASE_URL } from '../BaseUrl';
import { getCookie } from '../functions/Functions';
import { useSafeAsyncRequest } from '../hooks/useSafeAsyncRequest';

const RestaurantContext = createContext();

const RestaurantProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);
  const [branchListWeb, setBranchListWeb] = useState(null);
  const [pendingOrdersDelivery, setPendingOrdersDelivery] = useState(null);
  const [pendingOrdersDeliveryForSearch, setPendingOrdersDeliveryForSearch] = useState(null);
  const [deliveredOrdersDelivery, setDeliveredOrdersDelivery] = useState(null);
  const [deliveredOrdersDeliveryForSearch, setDeliveredOrdersDeliveryForSearch] = useState(null);
  const [ingredientGroupList, setIngredientGroupList] = useState(null);
  const [ingredientGroupForSearch, setIngredientGroupForSearch] = useState(null);
  const [ingredientItemList, setIngredientItemList] = useState(null);
  const [ingredientItemForSearch, setIngredientItemForSearch] = useState(null);
  const [ingredientItemStock, setIngredientItemStock] = useState(null);
  const [ingredientPurchaseHistory, setIngredientPurchaseHistory] = useState(null);
  const [ingredientPurchaseHistoryForSearch, setIngredientPurchaseHistoryForSearch] = useState(null);
  const [branchList, setBranchList] = useState(null);
  const [branchForSearch, setBranchforSearch] = useState(null);
  const [tableList, setTableList] = useState(null);
  const [tableForSearch, setTableforSearch] = useState(null);
  const [deptTagList, setDeptTagList] = useState(null);
  const [deptTagForSearch, setDeptTagForSearch] = useState(null);
  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [paymentTypeForSearch, setPaymentTypeforSearch] = useState(null);
  const [workPeriodList, setWorkPeriodList] = useState(null);
  const [workPeriodForSearch, setWorkPeriodListForSearch] = useState(null);
  const [submittedOrders, setSubmittedOrders] = useState(null);
  const [submittedOrdersForSearch, setSubmittedOrdersForSearch] = useState(null);
  const [settledOrders, setSettledOrders] = useState(null);
  const [settledOrdersForSearch, setSettledOrdersForSearch] = useState(null);
  const [kithcenNewOrders, setKithcenNewOrders] = useState(null);
  const [kithcenNewOrdersOnline, setKithcenNewOrdersOnline] = useState(null);
  const [allOrders, setAllOrders] = useState(null);
  const [allOrdersForSearch, setAllOrdersForSearch] = useState(null);
  const [allOnlineOrders, setAllOnlineOrders] = useState(null);
  const [allOnlineOrdersForSearch, setAllOnlineOrdersForSearch] = useState(null);
  const safeAxiosGet = useSafeAsyncRequest();

  useEffect(() => {
    getBranchWeb();
    if (getCookie() !== undefined) {
      getBranch();
      getTable();
      getDeptTag();
      getPaymentType();
      getWorkPeriod();
      getDeliveryPendingOrders();
      getDeliveryDeliveredOrders();
    }
  }, []);

  const getDeliveryPendingOrders = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/settings/get-assigned-orders";
    const data = await safeAxiosGet(ordersUrl, );

    if (data) {
      setPendingOrdersDelivery(data[0]);
      setPendingOrdersDeliveryForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedDeliveryPendingOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-assigned-orders?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setPendingOrdersDelivery(data[0]);
      setPendingOrdersDeliveryForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getDeliveryDeliveredOrders = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/settings/get-delivered-orders";
    const data = await safeAxiosGet(ordersUrl, );
    if (data) {
      setDeliveredOrdersDelivery(data[0]);
      setDeliveredOrdersDeliveryForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedDeliveryDeliveredOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-delivered-orders?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setDeliveredOrdersDelivery(data[0]);
      setDeliveredOrdersDeliveryForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getIngredientGroup = async () => {
    setLoading(true);
    const supplierUrl = BASE_URL + "/settings/get-ingredient_group";
    const data = await safeAxiosGet(supplierUrl, );
    if (data) {
      setIngredientGroupList(data[0]);
      setIngredientGroupForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedIngredientGroup = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_group?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setIngredientGroupList(data[0]);
      setIngredientGroupForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getIngredientItem = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_item";
    const data = await safeAxiosGet(url, );
    if (data) {
      setIngredientItemList(data[0]);
      setIngredientItemForSearch(data[1]);
      setIngredientItemStock(data[2]);
      setLoading(false);
    }
  };

  const setPaginatedIngredientItem = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_item?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setIngredientItemList(data[0]);
      setIngredientItemForSearch(data[1]);
      setIngredientItemStock(data[2]);
      setDataPaginating(false);
    }
  };

  const getBranchWeb = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/website/get-branch-web";
    const data = await safeAxiosGet(branchUrl);
    if (data) {
      setBranchListWeb(data);
      setLoading(false);
    }
  };

  const getBranch = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/settings/get-branch";
    const data = await safeAxiosGet(branchUrl, );
    if (data) {
      setBranchList(data[0]);
      setBranchforSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedBranch = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-branch?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setBranchList(data[0]);
      setBranchforSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getTable = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/settings/get-table";
    const data = await safeAxiosGet(branchUrl, );
    if (data) {
      setTableList(data[0]);
      setTableforSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedTable = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-table?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setTableList(data[0]);
      setTableforSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getPaymentType = async () => {
    setLoading(true);
    const paymentTypeUrl = BASE_URL + "/settings/get-payment-type";
    const data = await safeAxiosGet(paymentTypeUrl, );
    if (data) {
      setPaymentTypeList(data[0]);
      setPaymentTypeforSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedPaymentType = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-payment-type?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setPaymentTypeList(data[0]);
      setPaymentTypeforSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getDeptTag = async () => {
    setLoading(true);
    const deptTagUrl = BASE_URL + "/settings/get-dept-tag";
    const data = await safeAxiosGet(deptTagUrl, );
    if (data) {
      setDeptTagList(data[0]);
      setDeptTagForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedDeptTag = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-dept-tag?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setDeptTagList(data[0]);
      setDeptTagForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getWorkPeriod = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-work-period";
    const data = await safeAxiosGet(url, );
    if (data) {
      setWorkPeriodList(data[0]);
      setWorkPeriodListForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedWorkPeriod = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-work-period?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setWorkPeriodList(data[0]);
      setWorkPeriodListForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getSubmittedOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-submitted-orders";
    const data = await safeAxiosGet(url, );
    if (data) {
      setSubmittedOrders(data[0]);
      setSubmittedOrdersForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedSubmittedOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-submitted-orders?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setSubmittedOrders(data[0]);
      setSubmittedOrdersForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getSettledOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-settled-orders";
    const data = await safeAxiosGet(url, );
    if (data) {
      setSettledOrders(data[0]);
      setSettledOrdersForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedSettledOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-settled-orders?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setSettledOrders(data[0]);
      setSettledOrdersForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  const getKitchenNewOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders";
    const data = await safeAxiosGet(url, );
    if (data) {
      setKithcenNewOrders(data[0]);
      setLoading(false);
    }
  };

  const getKitchenNewOrdersOnline = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders-online";
    const data = await safeAxiosGet(url, );
    if (data) {
      setKithcenNewOrdersOnline(data[0]);
      setLoading(false);
    }
  };

  const getAllOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-order-history-all";
    const data = await safeAxiosGet(url, );
    if (data) {
      setAllOrdersForSearch(data);
      setLoading(false);
    }
  };

  const setPaginatedAllOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-order-history?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setAllOrders(data);
      setDataPaginating(false);
      setLoading(false);
    }
  };

  const getAllOnlineOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-online-order-history-all";
    const data = await safeAxiosGet(url, );
    if (data) {
      setAllOnlineOrdersForSearch(data);
      setLoading(false);
    }
  };

  const setPaginatedAllOnlineOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-online-order-history?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setAllOnlineOrders(data);
      setDataPaginating(false);
      setLoading(false);
    }
  };

  const getIngredientPurchase = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_purchase";
    const data = await safeAxiosGet(url);
    if (data) {
      setIngredientPurchaseHistory(data[0]);
      setIngredientPurchaseHistoryForSearch(data[1]);
      setLoading(false);
    }
  };

  const setPaginatedIngredientPurchase = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_purchase?page=" + pageNo;
    const data = await safeAxiosGet(url, );
    if (data) {
      setIngredientPurchaseHistory(data[0]);
      setIngredientPurchaseHistoryForSearch(data[1]);
      setDataPaginating(false);
    }
  };

  return (
      <RestaurantContext.Provider
          value={{
            loading,
            setLoading,
            getDeliveryPendingOrders,
            pendingOrdersDelivery,
            setPendingOrdersDelivery,
            pendingOrdersDeliveryForSearch,
            setPendingOrdersDeliveryForSearch,
            setPaginatedDeliveryPendingOrders,
            getDeliveryDeliveredOrders,
            deliveredOrdersDelivery,
            setDeliveredOrdersDelivery,
            deliveredOrdersDeliveryForSearch,
            setDeliveredOrdersDeliveryForSearch,
            setPaginatedDeliveryDeliveredOrders,
            getIngredientGroup,
            ingredientGroupList,
            setIngredientGroupList,
            setPaginatedIngredientGroup,
            ingredientGroupForSearch,
            setIngredientGroupForSearch,
            getIngredientItem,
            ingredientItemList,
            setIngredientItemList,
            setPaginatedIngredientItem,
            ingredientItemForSearch,
            setIngredientItemForSearch,
            ingredientItemStock,
            setIngredientItemStock,
            getIngredientPurchase,
            ingredientPurchaseHistory,
            setIngredientPurchaseHistory,
            setPaginatedIngredientPurchase,
            ingredientPurchaseHistoryForSearch,
            setIngredientPurchaseHistoryForSearch,
            getBranchWeb,
            branchListWeb,
            setBranchListWeb,
            getBranch,
            branchList,
            setBranchList,
            setPaginatedBranch,
            branchForSearch,
            setBranchforSearch,
            getTable,
            tableList,
            setTableList,
            setPaginatedTable,
            tableForSearch,
            setTableforSearch,
            getDeptTag,
            deptTagList,
            setDeptTagList,
            setPaginatedDeptTag,
            deptTagForSearch,
            setDeptTagForSearch,
            getPaymentType,
            paymentTypeList,
            setPaymentTypeList,
            setPaginatedPaymentType,
            paymentTypeForSearch,
            setPaymentTypeforSearch,
            getWorkPeriod,
            workPeriodList,
            setWorkPeriodList,
            setPaginatedWorkPeriod,
            workPeriodForSearch,
            setWorkPeriodListForSearch,
            getSubmittedOrders,
            submittedOrders,
            setSubmittedOrders,
            setPaginatedSubmittedOrders,
            submittedOrdersForSearch,
            setSubmittedOrdersForSearch,
            getSettledOrders,
            settledOrders,
            setSettledOrders,
            setPaginatedSettledOrders,
            settledOrdersForSearch,
            setSettledOrdersForSearch,
            getKitchenNewOrders,
            kithcenNewOrders,
            setKithcenNewOrders,
            getKitchenNewOrdersOnline,
            kithcenNewOrdersOnline,
            setKithcenNewOrdersOnline,
            getAllOrders,
            allOrders,
            setAllOrders,
            setPaginatedAllOrders,
            allOrdersForSearch,
            setAllOrdersForSearch,
            getAllOnlineOrders,
            allOnlineOrders,
            setAllOnlineOrders,
            setPaginatedAllOnlineOrders,
            allOnlineOrdersForSearch,
            setAllOnlineOrdersForSearch,
            dataPaginating,
            setDataPaginating,
          }}
      >
        {children}
      </RestaurantContext.Provider>
  );
};

export { RestaurantContext, RestaurantProvider };
