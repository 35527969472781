// config
let production = 'YES';//YES ==live NO ==localhost
export let SAAS_APPLICATION = 'NO'; //YES|NO

// default config
export const saas_key = '$2a$12$pkLmD5jZdxd6bSNcTg9YT.g2mXz5gS8JTArdIg68c8RK/d7w2y2Gu';
export const saas_apiUrl = 'https://cafe.clooudbase.com';

export let URL = `http://localhost:8000`;
export let BASE_URL = `http://localhost:8000`;
export let saas_apiParams = '';
export let saas_form_data = null;

if (production === "YES") {
    // for production
    BASE_URL = `${window.location.protocol}//${window.location.host}/api`;
    URL = `${window.location.protocol}//${window.location.host}`;

    // saas config
    saas_apiParams = `saas_key=${saas_key}&domain=${BASE_URL}`;
    saas_form_data = {
        'saas_key': saas_key,
        'domain': BASE_URL
    };
} else {
    // for localhost development
    BASE_URL = `http://localhost:8000/api`;
    URL = `http://localhost:8000`;

    // saas config
    saas_apiParams = `saas_key=${saas_key}&domain=prince.automatefood.com`;
    saas_form_data = {
        'saas_key': saas_key,
        'domain': 'prince.automatefood.com'
    };

}
