import React, {useContext, useEffect, useState} from "react";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import moment from "moment";

//functions
import {
    _t, currencySymbolLeft, currencySymbolRight, formatPrice,
    
    modalLoading,
    pagination,
    paginationLoading,
    showingData,
    tableLoading,
    printElement
} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";

//3rd party packages
import {Helmet} from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import {SettingsContext} from "../../../../../contexts/Settings";
import {BASE_URL} from "../../../../../BaseUrl";
import {toast} from "react-toastify";
import {Controller, useForm} from "react-hook-form";
import {AsyncPaginate} from "react-select-async-paginate";
import CompanyInfo from "../../../../components/CompanyInfo";
import DatePicker from "react-datepicker";


const ExpenseList = () => {
    const {t} = useTranslation();
    const [search, setSearch] = useState('')
    const [ExpenseList, setExpenseList] = useState({})
    const [dataPaginating, setDataPaginating] = useState(false)
    const [image, setImage] = useState('')
    const [form, setForm] = useState('')
    const [expense, setExpense] = useState({})
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let [newExpense, setnewExpense] = useState({
        date: new Date().toISOString().slice(0, 10),
        edit: false,
        editSlug: null,
        uploading: false,
    });
    let [searchedExpense, setsearchedExpense] = useState({
        list: null,
        searched: false,
    });

    //search Expense here
    const handleSearch = (e = null) => {
        let searchInput = e ? e.target.value.toLowerCase() : '';
        setSearch(searchInput)
        setsearchedExpense({...searchedExpense, searched: searchInput.length !== 0});
        setLoading(true);
        let ExpenseUrl = BASE_URL + "/settings/expenses?page=1&perPage=10";
        let fromDate = startDate?.toISOString() ?? null
        let toDate = endDate?.toISOString() ?? null

        if (toDate && fromDate) {
            ExpenseUrl += '&startDate=' + fromDate + '&endDate=' + toDate;
        }
        if (searchInput.length) {
            ExpenseUrl += '&term=' + searchInput;
        }
        return window.axiosInstance.get(ExpenseUrl, )
            .then((res) => {
                setExpenseList(res.data)
                setLoading(false);
            });
    };

    const handleSetEdit = (item) => {
        let Expense = ExpenseList.data.find((item) => item.id === item.id);
        let category = Expense.subCategory
        let account = Expense.account
        setValue('subCategory', {
            value: category.id,
            id: category.id,
            label: category.name,
            code: category.code,
        })
        setValue('account', {
            value: account.id,
            id: account.id,
            label: account.bankName,
            availableBalance: account.availableBalance,
            accountNumber: account.accountNumber
        })
        setValue('availableBalance', account.availableBalance)
        setValue('reason', Expense.reason)
        setValue('amount', Expense.transaction.amount)
        setValue('chequeNo', Expense.chequeNo)
        setValue('voucherNo', Expense.voucherNo)
        setValue('note', Expense.note)
        setValue('date', Expense.date)
        setValue('status', Expense.status)
        setValue('slug', Expense.slug)
        setExpense(true)
        setImage(Expense.image)
        setnewExpense({
            ...newExpense,
            editSlug: Expense.slug,
            edit: true,
        });
    };
    const setPaginatedExpense = (pageNo) => {
        setDataPaginating(true);
        let url = BASE_URL + `/settings/expenses?page=${pageNo}&perPage=10`;
        let fromDate = startDate?.toISOString() ?? null
        let toDate = endDate?.toISOString() ?? null

        if (search.length) {
            url += '&term=' + search;
        }
        if (toDate && fromDate) {
            url += '&startDate=' + fromDate + '&endDate=' + toDate;
        }
        return window.axiosInstance.get(url, )
            .then((res) => {
                setExpenseList(res.data);
                setDataPaginating(false);
            })
            .catch(() => {
            });
    };


    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        control,
        formState: {errors}
    } = useForm({defaultValues: {fromExpense: "", toExpense: ""}, mode: "onBlur"});
    const onSubmit = data => {
        const accountUrl = newExpense.edit ? BASE_URL + "/settings/expenses/" + data.slug : BASE_URL + "/settings/expenses";
        setnewExpense({
            ...newExpense,
            uploading: true,
        });
        data.subCategory = {id: data.subCategory.id, code: data.subCategory.code};
        data.account = {accountNumber: data.account.accountNumber, id: data.account.id};
        data.image = form;
        let action = newExpense.edit ? 'put' : 'post';
        return window.axiosInstance[action](accountUrl, data, )
            .then((res) => {
                setLoading(false);
                toast.success(!newExpense.edit ? `${_t(t("Expense added successfully!"))}` : `${_t(t("Expense updated successfully!"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
                setnewExpense({
                    name: "",
                    edit: false,
                    editSlug: null,
                    uploading: false,
                });
                reset({
                    subCategory: '',
                    account: '',
                    availableBalance: 0,
                    reason: '',
                    amount: '',
                    chequeNo: '',
                    voucherNo: '',
                    date: new Date().toISOString().slice(0, 10),
                    status: 1,
                    note: '',
                })
                setExpense(false)
                setImage('')
            })
            .catch((error) => {
                setLoading(false);
                setnewExpense({
                    ...newExpense,
                    uploading: false,
                });
                if (error.response.data.errors) {
                    if (error.response.data.errors) {
                        Object.keys(error.response.data.errors).forEach((item) => {
                            toast.error(
                                `${error.response.data.errors[item]}`,
                                {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                }
                            );
                        })
                    } else {
                        toast.error(`${_t(t("Please try again"))}`, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            className: "text-center toast-notification",
                        });
                    }
                }
            });
    };

    const loadOptionsCategories = async (search, loadedOptions, {page}) => {
        let ExpenseUrl = BASE_URL + `/settings/expense-sub-categories?page=${page}&perPage=10`;
        if (search.length) {
            ExpenseUrl += '&term=' + search;
        }
        let res = await window.axiosInstance.get(ExpenseUrl)
        return {
            options: res.data?.data?.map(item => {
                return {
                    value: item.id,
                    id: item.id,
                    label: item.name,
                    code: item.code,
                }
            }),
            hasMore: res?.data?.meta.last_page > res?.data?.meta.current_page,
            additional: {
                page: page + 1,
            },
        };
    }
    const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
        const bottomBorder = (scrollHeight - clientHeight) / 2;
        return bottomBorder < scrollTop;
    };


    const loadOptionsAccounts = async (search, loadedOptions, {page}) => {
        let ExpenseUrl = BASE_URL + `/settings/accounts?page=${page}&perPage=10`;
        if (search.length) {
            ExpenseUrl += '&term=' + search;
        }
        if (getValues('from_account')) {
            ExpenseUrl += '&except=' + getValues('from_account')?.value;
        }
        let res = await window.axiosInstance.get(ExpenseUrl)
        return {
            options: res.data?.data?.map(item => {
                return {
                    value: item.id,
                    id: item.id,
                    label: item.bankName,
                    availableBalance: item.availableBalance,
                    accountNumber: item.accountNumber
                }
            }),
            hasMore: res?.data?.meta.last_page > res?.data?.meta.current_page,
            additional: {
                page: page + 1,
            },
        };
    }
    const shouldLoadMoreAccounts = (scrollHeight, clientHeight, scrollTop) => {
        const bottomBorder = (scrollHeight - clientHeight) / 2;
        return bottomBorder < scrollTop;
    };


    const onFileChange = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        if (
            file.size < 2111775 &&
            (file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/gif')
        ) {
            reader.onloadend = (file) => {
                setForm(reader.result)
            }
            reader.readAsDataURL(file)
            setImage(URL.createObjectURL(file))
        } else {
            toast.error(`${_t(t("image_error"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        }
    }


    const dateRange = (value, type) => {
        let fromDate = startDate, toDate = endDate
        if (type === 'start') {
            fromDate = value?.toISOString();
            toDate = toDate?.toISOString() ?? null
        } else {
            toDate = value?.toISOString();
            fromDate = fromDate?.toISOString() ?? null
        }
        if (toDate && fromDate) {
            setLoading(true);
            let url = BASE_URL + "/settings/expenses?page=1&perPage=10";
            if (search.length) {
                url += '&term=' + search;
            }
            if (toDate && fromDate) {
                url += '&startDate=' + fromDate + '&endDate=' + toDate;
            }
            return window.axiosInstance.get(url, )
                .then((res) => {
                    setExpenseList(res.data)
                    setLoading(false);
                });
        }else{
            setLoading(true);
            let url = BASE_URL + "/settings/expenses?page=1&perPage=10";
            if (search.length) {
                url += '&term=' + search;
            }
            return window.axiosInstance.get(url, )
                .then((res) => {
                    setExpenseList(res.data)
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>{_t(t("Expenses"))}</title>
            </Helmet>

            {/* Add modal */}
            <div className="modal fade" id="addExpense" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {!newExpense.edit
                                        ? _t(t("create_expense"))
                                        : _t(t("Update_expense"))}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {newExpense.uploading === false ? (

                                <form onSubmit={handleSubmit(onSubmit)} id="accountForm">
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-6">
                                                        <label
                                                            htmlFor="expense_reason"> {_t(t("expense_reason"))} <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            id="reason"
                                                            type="text"
                                                            name="reason"
                                                            {...register("reason", {required: true})}
                                                            className="form-control"
                                                            placeholder={_t(t("expense_reason"))}
                                                        />
                                                        {errors.reason && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="from_account">{_t(t("category"))} <span
                                                            className="text-danger">*</span></label>
                                                        <Controller
                                                            control={control}
                                                            name="subCategory"
                                                            rules={{required: true}}
                                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                                <AsyncPaginate
                                                                    placeholder={_t(t("Category_placeholder"))}
                                                                    value={value}
                                                                    isClearable
                                                                    defaultValue={''}
                                                                    onBlur={onBlur}
                                                                    loadOptions={loadOptionsCategories}
                                                                    shouldLoadMore={shouldLoadMore}
                                                                    onChange={(data) => {
                                                                        onChange(data)
                                                                    }}
                                                                    additional={{
                                                                        page: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.category && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="to_account">{_t(t("account"))} <span
                                                            className="text-danger">*</span></label>
                                                        <Controller
                                                            control={control}
                                                            name="account"
                                                            rules={{required: true}}
                                                            render={({field: {onChange, onBlur, value, ref}}) => (
                                                                <AsyncPaginate
                                                                    placeholder={_t(t("account_placeholder"))}
                                                                    value={value}
                                                                    isClearable
                                                                    defaultValue={''}
                                                                    onBlur={onBlur}
                                                                    loadOptions={loadOptionsAccounts}
                                                                    shouldLoadMore={shouldLoadMoreAccounts}
                                                                    onChange={(data) => {
                                                                        onChange(data)
                                                                        console.log(data)
                                                                        setValue("availableBalance", data?.availableBalance ?? 0)
                                                                    }}
                                                                    additional={{
                                                                        page: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.account && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label
                                                            htmlFor="available_Expense"> {_t(t("available_balance"))}</label>
                                                        <input
                                                            id="available_Expense"
                                                            type="text" readOnly={true}
                                                            name="availableBalance"
                                                            value={getValues('availableBalance')}
                                                            {...register("availableBalance", {})}
                                                            className="form-control"
                                                            placeholder={_t(t("available_balance"))}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="row mb-3">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="amount"> {_t(t("Amount"))} <span
                                                            className="text-danger">*</span></label>
                                                        <input
                                                            id="amount"
                                                            type="number"
                                                            {...register("amount", {required: true})}
                                                            className="form-control"
                                                            name="amount"
                                                            placeholder={_t(t("amount_placeholder"))}
                                                        />
                                                        {errors.amount && <span
                                                            className="text-danger">{_t(t("This field is required"))}</span>}

                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="amount"> {_t(t("cheque_no"))} </label>
                                                        <input
                                                            id="cheque_no"
                                                            type="number"
                                                            {...register("chequeNo", {})}
                                                            className="form-control"
                                                            name="chequeNo"
                                                            placeholder={_t(t("cheque_placeholder"))}
                                                        />

                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="amount"> {_t(t("voucher_no"))} </label>
                                                        <input
                                                            id="voucher_no"
                                                            type="number"
                                                            {...register("voucherNo", {})}
                                                            className="form-control"
                                                            name="voucherNo"
                                                            placeholder={_t(t("voucher_placeholder"))}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="status"> {_t(t("note"))}</label>
                                                    <textarea
                                                        id="note"
                                                        name="note"
                                                        {...register("note", {})}
                                                        className="form-control"
                                                        placeholder={_t(t("note_placeholder"))}
                                                    />
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="date"> {_t(t("date"))}</label>
                                                        <input
                                                            id="date"
                                                            type="date"
                                                            defaultValue={newExpense.date || ""}
                                                            {...register("date", {})}
                                                            className="form-control"
                                                            name="date"
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="status"> {_t(t("status"))}</label>
                                                        <select
                                                            id="status"
                                                            name="status"
                                                            className="form-control"
                                                            defaultValue={1}
                                                            {...register("status", {})}
                                                        >
                                                            <option value="1">{_t(t("active"))}</option>
                                                            <option value="0">{_t(t("in_active"))}</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="image"> {_t(t("image"))}</label>
                                                        <input
                                                            id="image"
                                                            type="file" accept="image/png, image/jpeg"
                                                            {...register("image", {})}
                                                            className="form-control"
                                                            name="image"
                                                            onChange={onFileChange}
                                                        />
                                                        {image ?
                                                            <div className="bg-light mt-4 w-100">
                                                                <img
                                                                    src={image}
                                                                    className="img-fluid"
                                                                    alt={_t(t("image_alt"))}
                                                                />
                                                            </div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                                >
                                                    {!newExpense.edit
                                                        ? _t(t("Save"))
                                                        : _t(t("Update"))}
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                                                    data-dismiss="modal"
                                                >
                                                    {_t(t("Close"))}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <div key="fragment2">
                                    <div className="text-center text-primary font-weight-bold text-uppercase">
                                        {_t(t("Please wait"))}
                                    </div>
                                    {modalLoading(3)}
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
            {/* Add modal Ends*/
            }

            {/* View modal */}
            <div className="modal fade" id="viewExpense" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    {expense?.id &&
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <div className="fk-sm-card__content">
                                    <h5 className="text-capitalize fk-sm-card__title">
                                        {_t(t("Expenses Details"))}
                                    </h5>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div id="viewExpense-print" className="modal-body section-to-print">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="invoice p-3 mb-3">
                                            <div className="row invoice-info">
                                                <div className="col-md-6 col-6 invoice-col float-left">
                                                    <CompanyInfo/>
                                                </div>
                                                <div className="col-md-6 col-6 invoice-col float-right text-md-right">
                                                    <h5> {_t(t("Expenses Details"))}</h5>
                                                    <strong>{_t(t("date"))}:</strong> {moment(expense.date).format("Do MMM, YYYY")}<br/>
                                                    <strong>{_t(t("created_by"))}: </strong> {expense.createdBy}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="table-responsive table-custom text-center">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>{_t(t("image"))}</th>
                                                <th>{_t(t("expense_reason"))}</th>
                                                <th>{_t(t("amount"))}</th>
                                                <th>{_t(t("account"))}</th>
                                                <th>{_t(t("cheque_no"))}</th>
                                                <th>{_t(t("voucher_no"))}</th>
                                                <th>{_t(t("note"))}</th>
                                                <th>{_t(t("status"))}</th>
                                                <th>{_t(t("created_by"))}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    {expense.image ?
                                                    <a href="#" id="show-modal"><img
                                                        src={expense.image}
                                                        loading="lazy"
                                                        className="rounded preview-sm"/></a>
                                                    : <div
                                                        className="bg-secondary rounded no-preview-sm">
                                                        <small>No Preview</small>
                                                    </div>}
                                                </td>
                                                <td>
                                                    <span>{expense.reason}</span>
                                                </td>
                                                <td>
                                                    {currencySymbolLeft()}
                                                    {formatPrice(expense.amount)}
                                                    {currencySymbolRight()}
                                                </td>
                                                <td>
                                                    {expense.account.label && <span>{expense.account.label}</span>}
                                                </td>
                                                <td>
                                                    {expense.transaction.cheque_no && <span>{expense.transaction.cheque_no}</span>}
                                                </td>
                                                <td>
                                                    {expense.transaction.receipt_no && <span>{expense.transaction.receipt_no}</span>}
                                                </td>
                                                <td>
                                                    <span dangerouslySetInnerHTML={{ __html: expense.note }}></span>
                                                </td>
                                                <td>
                                                    {expense.status === 1 ?
                                                        <span
                                                            className="badge bg-success">{_t(t("active"))}</span>
                                                        :
                                                        <span
                                                            className="badge bg-danger">{_t(t("in_active"))}</span>
                                                    }
                                                </td>
                                                <td className="text-right">{expense.createdBy}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button" onClick={() => printElement("viewExpense-print")}
                                    className="btn btn-info xsm-text text-uppercase "
                                >
                                    <i className="fa fa-print"></i> {_t(t("Print"))}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary xsm-text text-uppercase"
                                    data-dismiss="modal"
                                >
                                    {_t(t("Close"))}
                                </button>
                            </div>

                        </div>}
                </div>
            </div>
            {/* View modal Ends*/
            }

            {/* main body */
            }
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar/>
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                        <div key="fragment3">
                                            {/* next page data spin loading */}
                                            <div className={`${(dataPaginating || loading) && "loading"}`}></div>
                                            {/* spin loading ends */}
                                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                <div className="col-md-4 col-lg-5 t-mb-15 mb-md-0">
                                                    <ul className="t-list fk-breadcrumb">
                                                        <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedExpense.searched
                                      ? _t(t("Expense List"))
                                      : _t(t("Search Result"))}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-8 col-lg-7">
                                                    <div className="row gx-3 align-items-center">
                                                        {/* Search group */}
                                                        <div className="col-md-3 t-mb-15 mb-md-0">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => {
                                                                    setStartDate(date)
                                                                    dateRange(date, 'start')
                                                                }}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control sm-text py-2 t-mb-15 mb-md-0"
                                                                placeholderText={_t(t("From date"))}
                                                                shouldCloseOnSelect={false}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 t-mb-15 mb-md-0">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => {
                                                                    setEndDate(date)
                                                                    dateRange(date, 'end')
                                                                }}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control sm-text py-2"
                                                                placeholderText={_t(t("To date"))}
                                                                shouldCloseOnSelect={false}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 t-mb-15 mb-md-0">
                                                            <div className="input-group">
                                                                <div className="form-file">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 form-control--light-1 rounded-0"
                                                                        placeholder={_t(t("Search")) + ".."}
                                                                        onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="button">
                                                                    <i className="fa fa-search"
                                                                       aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        {/* Add group modal trigger button */}
                                                        <div className="col-md-2 text-md-right">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                                                data-toggle="modal"
                                                                data-target="#addExpense"
                                                                onClick={() => {
                                                                    setnewExpense({
                                                                        ...newExpense,
                                                                        edit: false,
                                                                        uploading: false,
                                                                    });
                                                                    reset({
                                                                        subCategory: '',
                                                                        account: '',
                                                                        availableBalance: 0,
                                                                        reason: '',
                                                                        amount: '',
                                                                        chequeNo: '',
                                                                        voucherNo: '',
                                                                        date: new Date().toISOString().slice(0, 10),
                                                                        status: 1,
                                                                        note: '',
                                                                    })
                                                                }}
                                                            >
                                                                {_t(t("add new"))}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Table */}
                                            {newExpense.uploading === true || loading === true ? (
                                                tableLoading()
                                            ) : (
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-hover min-table-height">
                                                        <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                #
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("image"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("expense_reason"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("category"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("sub_category"))}
                                                            </th>
                                                            <th
                                                                scope="amount"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("amount"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("account"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border">
                                                                {_t(t("date"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border">
                                                                {_t(t("status"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Action"))}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {ExpenseList && [
                                                            ExpenseList?.data?.length === 0 ? (
                                                                <tr className="align-middle" key={'12h1'}>
                                                                    <td
                                                                        scope="row"
                                                                        colSpan="9"
                                                                        className="xsm-text align-middle text-center"
                                                                    >
                                                                        {_t(t("No data available"))}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ExpenseList?.data?.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <tr
                                                                                className="align-middle"
                                                                                key={index}
                                                                            >
                                                                                <th
                                                                                    scope="row"
                                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                                >
                                                                                    {index +
                                                                                        1 +
                                                                                        (ExpenseList.meta.current_page -
                                                                                            1) *
                                                                                        ExpenseList.meta.per_page}
                                                                                </th>

                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.image ?
                                                                                        <a href="#" id="show-modal"><img
                                                                                            src={item.image}
                                                                                            loading="lazy"
                                                                                            className="rounded preview-sm"/></a>
                                                                                        : <div
                                                                                            className="bg-secondary rounded no-preview-sm">
                                                                                            <small>No Preview</small>
                                                                                        </div>}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    <a data-toggle="modal"
                                                                                       data-target="#viewExpense">{item.reason}</a>

                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.category?.name} [{item.category?.code}]
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.subCategory?.name} [{item.subCategory?.code}]
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {currencySymbolLeft()}
                                                                                    {formatPrice(item.transaction.amount)}
                                                                                    {currencySymbolRight()}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.account.label}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {moment(item.date).format("Do MMM, YYYY")}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.status === 1 ?
                                                                                        <span
                                                                                            className="badge bg-success">{_t(t("active"))}</span>
                                                                                        :
                                                                                        <span
                                                                                            className="badge bg-danger">{_t(t("in_active"))}</span>
                                                                                    }
                                                                                </td>


                                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                                    <div className="dropdown">
                                                                                        <button
                                                                                            className="btn t-bg-clear t-text-dark--light-40"
                                                                                            type="button"
                                                                                            data-toggle="dropdown"
                                                                                        >
                                                                                            <i className="fa fa-ellipsis-h"></i>
                                                                                        </button>
                                                                                        <div
                                                                                            className="dropdown-menu">
                                                                                            <button
                                                                                                className="dropdown-item sm-text text-capitalize"
                                                                                                onClick={() =>
                                                                                                    setExpense(
                                                                                                        item
                                                                                                    )
                                                                                                }
                                                                                                data-toggle="modal"
                                                                                                data-target="#viewExpense"
                                                                                            >
                                                        <span className="">
                                                          <i className="fa fa-eye"></i>
                                                        </span> {_t(t("View"))}
                                                                                            </button>
                                                                                            <button
                                                                                                className="dropdown-item sm-text text-capitalize"
                                                                                                onClick={() =>
                                                                                                    handleSetEdit(
                                                                                                        item
                                                                                                    )
                                                                                                }
                                                                                                data-toggle="modal"
                                                                                                data-target="#addExpense"
                                                                                            >
                                                        <span className="t-mr-8">
                                                          <i className="fa fa-pencil"></i>
                                                        </span>
                                                                                                {_t(t("Edit"))}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            ),
                                                        ]}
                                                        </tbody>
                                                    </table>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* pagination loading effect */}
                            {newExpense.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedExpense.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(
                                                            ExpenseList.meta,
                                                            setPaginatedExpense
                                                        )}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(ExpenseList.meta)}
                                  </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => {
                                                                    setsearchedExpense({
                                                                        ...searchedExpense,
                                                                        searched: false,
                                                                    })
                                                                    setSearch('');
                                                                    handleSearch()
                                                                }

                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(
                                      ExpenseList
                                  )}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */
            }
        </>
    )
        ;
};

export default ExpenseList;
