import React, { useState, useEffect } from "react";
import {BASE_URL, URL} from "../BaseUrl";
import { getCookie, getSystemSettings } from "../functions/Functions";
import { useSafeAsyncRequest } from '../hooks/useSafeAsyncRequest'; // استيراد الخطاف المخصص

const SettingsContext = React.createContext();

const SettingsProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [dataPaginating, setDataPaginating] = useState(false);
    const [permissionGroup, setPermissionGroup] = useState(null);
    const [permissionGroupForSearch, setPermissionGroupForSearch] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [languageList, setLanguageList] = useState(null);
    const [navLanguageList, setNavLanguageList] = useState(null);
    const [languageListForSearch, setLanguageListForSearch] = useState(null);
    const [currencyList, setCurrencyList] = useState(null);
    const [navCurrencyList, setNavCurrencyList] = useState(null);
    const [currencyListForSearch, setCurrencyListForSearch] = useState(null);
    const [generalSettings, setGeneralSettings] = useState(null);
    const [smtp, setSmtp] = useState({
        MAIL_MAILER: null,
        MAIL_HOST: null,
        MAIL_PORT: null,
        MAIL_USERNAME: null,
        MAIL_PASSWORD: null,
        MAIL_ENCRYPTION: null,
        MAIL_FROM_ADDRESS: null,
        MAIL_FROM_NAME: null,
    });
    const [showManageStock, setshowManageStock] = useState(true);
    const [paymentDetails, setpaymentDetails] = useState([]);
    const [paypal_client_id, setpaypal_client_id] = useState(null);
    const safeAxiosGet = useSafeAsyncRequest();

    useEffect(() => {
        const fetchData = async () => {
            getCurrency();
            getSettings();

            if (getCookie() !== undefined) {
                getSmtp();
                getPermissionGroups();
            }
        };

        const updateManageStockValue = async () => {
            const url = BASE_URL + `/settings/show-manage-stock-menu-info`;
            const data = await safeAxiosGet(url, );
            if (data) {
                if (data.length == 0 || data[0].value == 1) {
                    setshowManageStock(true);
                } else {
                    setshowManageStock(false);
                }
            }
        };

        const getpaypalpaid = async () => {
            const url = BASE_URL + `/get-payment-client-id`;
            const data = await safeAxiosGet(url, );
            if (data) {
                if (data === " ") {
                    setpaypal_client_id(null);
                } else {
                    setpaypal_client_id(data.value);
                }
                setpaymentDetails(data);
            }
        };

        fetchData();
        const checkMngStk = setInterval(updateManageStockValue, 5000);
        // const checkpaypal = setInterval(getpaypalpaid, 10000);
        updateManageStockValue();
        // getpaypalpaid();
        return () => {
            clearInterval(checkMngStk);
            // clearInterval(checkpaypal);
        }
    }, []);

    const getLanguages = async () => {
        setLoading(true);
        const langUrl = BASE_URL + "/settings/get-lang";
        const data = await safeAxiosGet(langUrl);
        if (data) {
            setLanguageList(data[0]);
            setNavLanguageList(data[1]);
            setLanguageListForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedLanguages = async (pageNo) => {
        setDataPaginating(true);
        const langUrl = BASE_URL + "/settings/get-lang?page=" + pageNo;
        const data = await safeAxiosGet(langUrl);
        if (data) {
            setLanguageList(data[0]);
            setDataPaginating(false);
        }
    };

    const getSmtp = async () => {
        setLoading(true);
        const smtpUrl = BASE_URL + "/settings/get-smtp";
        const data = await safeAxiosGet(smtpUrl, );
        if (data) {
            setSmtp({
                MAIL_MAILER: data[0].MAIL_MAILER,
                MAIL_HOST: data[0].MAIL_HOST,
                MAIL_PORT: data[0].MAIL_PORT,
                MAIL_USERNAME: data[0].MAIL_USERNAME,
                MAIL_PASSWORD: data[0].MAIL_PASSWORD,
                MAIL_ENCRYPTION: data[0].MAIL_ENCRYPTION,
                MAIL_FROM_ADDRESS: data[0].MAIL_FROM_ADDRESS,
                MAIL_FROM_NAME: data[0].MAIL_FROM_NAME,
            });
            setLoading(false);
        }
    };

    const getPermissionGroups = async () => {
        setLoading(true);
        const permissionGroupUrl = BASE_URL + "/settings/permission-group-list";
        const data = await safeAxiosGet(permissionGroupUrl, );
        if (data) {
            setPermissionGroup(data[0]);
            setPermissionGroupForSearch(data[1]);
            setPermissions(data[2]);
            setLoading(false);
        }
    };

    const setPaginatedGropus = async (pageNo) => {
        setDataPaginating(true);
        const url = BASE_URL + "/settings/permission-group-list?page=" + pageNo;
        const data = await safeAxiosGet(url, );
        if (data) {
            setPermissionGroup(data[0]);
            setPermissionGroupForSearch(data[1]);
            setPermissions(data[2]);
            setDataPaginating(false);
        }
    };

    const getCurrency = async () => {
        setLoading(true);
        const currencyUrl = BASE_URL + "/settings/get-currency";
        const data = await safeAxiosGet(currencyUrl);
        if (data) {
            setCurrencyList(data[0]);
            setNavCurrencyList(data[1]);
            setCurrencyListForSearch(data[1]);
            setLoading(false);
        }
    };

    const setPaginatedCurrencies = async (pageNo) => {
        setDataPaginating(true);
        const currencyUrl = BASE_URL + "/settings/get-currency?page=" + pageNo;
        const data = await safeAxiosGet(currencyUrl);
        if (data) {
            setCurrencyList(data[0]);
            setDataPaginating(false);
        }
    };

    const getSettings = async () => {
        setLoading(true);
        const url = BASE_URL + "/settings/general-settings";
        const data = await safeAxiosGet(url);
        if (data) {
            setGeneralSettings(data);
            const favicon = document.getElementById("favicon");
            favicon.href = URL + getSystemSettings(data, "favicon");
            setLoading(false);
        }
    };

    return (
        <SettingsContext.Provider
            value={{
                loading,
                setLoading,
                generalSettings,
                setGeneralSettings,
                getLanguages,
                getCurrency,
                getSettings,
                currencyList,
                setCurrencyList,
                setPaginatedCurrencies,
                navCurrencyList,
                setNavCurrencyList,
                currencyListForSearch,
                setCurrencyListForSearch,
                languageList,
                setLanguageList,
                setPaginatedLanguages,
                navLanguageList,
                setNavLanguageList,
                languageListForSearch,
                setLanguageListForSearch,
                smtp,
                getSmtp,
                setSmtp,
                getPermissionGroups,
                permissionGroup,
                setPermissionGroup,
                setPaginatedGropus,
                permissionGroupForSearch,
                setPermissionGroupForSearch,
                permissions,
                setPermissions,
                dataPaginating,
                setDataPaginating,
                showManageStock,
                setshowManageStock,
                paypal_client_id,
                setpaypal_client_id,
                paymentDetails,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export { SettingsContext, SettingsProvider };
