import React, {useContext, useEffect, useState} from "react";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import moment from "moment";

//functions
import {
    _t, currencySymbolLeft, currencySymbolRight, formatPrice,
    pagination,
    paginationLoading,
    showingData,
    tableLoading,
} from "../../../../../functions/Functions";
import {useTranslation} from "react-i18next";

//3rd party packages
import {Helmet} from "react-helmet";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";
import {SettingsContext} from "../../../../../contexts/Settings";
import {BASE_URL} from "../../../../../BaseUrl";

import DatePicker from "react-datepicker";


const TransactionList = () => {
    const {t} = useTranslation();
    const [search, setSearch] = useState('')
    const [TransactionList, setBalanceList] = useState({})
    const [dataPaginating, setDataPaginating] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [transfer, setTransfer] = useState({})
    let {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    let [newTransfer, setnewTransfer] = useState({
        date: new Date().toISOString().slice(0, 10),
        edit: false,
        editSlug: null,
        uploading: false,
    });
    let [searchedTransfer, setsearchedTransfer] = useState({
        list: null,
        searched: false,
    });

    //search Account here
    const handleSearch = (e = null) => {
        let searchInput = e ? e.target.value.toLowerCase() : '';
        setSearch(searchInput)
        setsearchedTransfer({...searchedTransfer, searched: searchInput.length !== 0});
        setLoading(true);
        let url = BASE_URL + "/settings/transactions?page=1&perPage=10";
        let fromDate = startDate?.toISOString() ?? null
        let toDate = endDate?.toISOString() ?? null

        if (toDate && fromDate) {
            url += '&startDate=' + fromDate + '&endDate=' + toDate;
        }
        if (searchInput.length) {
            url += '&term=' + searchInput;
        }
        return window.axiosInstance.get(url, )
            .then((res) => {
                setBalanceList(res.data)
                setLoading(false);
            });
    };


    const setPaginatedAccount = (pageNo) => {
        setDataPaginating(true);
        let url = BASE_URL + `/settings/transactions?page=${pageNo}&perPage=10`;
        let fromDate = startDate?.toISOString() ?? null
        let toDate = endDate?.toISOString() ?? null

        if (search.length) {
            url += '&term=' + search;
        }
        if (toDate && fromDate) {
            url += '&startDate=' + fromDate + '&endDate=' + toDate;
        }
        return window.axiosInstance.get(url, )
            .then((res) => {
                setBalanceList(res.data);
                setDataPaginating(false);
            })
            .catch(() => {
            });
    };

    const dateRange = (value, type) => {
        let fromDate = startDate, toDate = endDate
        if (type === 'start') {
            fromDate = value.toISOString();
            toDate = toDate?.toISOString() ?? null
        } else {
            toDate = value.toISOString();
            fromDate = fromDate?.toISOString() ?? null
        }
        if (toDate && fromDate) {
            setLoading(true);
            let url = BASE_URL + "/settings/transactions?page=1&perPage=10";
            if (search.length) {
                url += '&term=' + search;
            }
            if (toDate && fromDate) {
                url += '&startDate=' + fromDate + '&endDate=' + toDate;
            }
            return window.axiosInstance.get(url, )
                .then((res) => {
                    setBalanceList(res.data)
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>{_t(t("Transaction History"))}</title>
            </Helmet>

            {/* main body */
            }
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ManageSidebar/>
                        </div>
                        {/* left Sidebar ends */}

                        {/* Rightbar contents */}
                        <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
                            <div className="t-bg-white">
                                <div className="fk-scroll--pos-menu" data-simplebar>
                                    <div className="t-pl-15 t-pr-15">
                                        {/* Loading effect */}
                                        <div key="fragment3">
                                            {/* next page data spin loading */}
                                            <div className={`${(dataPaginating || loading) && "loading"}`}></div>
                                            {/* spin loading ends */}
                                            <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                                                <div className="col-md-4 col-lg-5 t-mb-15 mb-md-0">
                                                    <ul className="t-list fk-breadcrumb">
                                                        <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedTransfer.searched
                                      ? _t(t("Transaction History"))
                                      : _t(t("Search Result"))}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-8 col-lg-7">
                                                    <div className="row gx-3 align-items-center">
                                                        {/* Search group */}
                                                        <div className="col-md-3 t-mb-15 mb-md-0">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => {
                                                                    setStartDate(date)
                                                                    dateRange(date, 'start')
                                                                }}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control sm-text py-2 t-mb-15 mb-md-0"
                                                                placeholderText={_t(t("From date"))}
                                                                shouldCloseOnSelect={false}
                                                            />
                                                        </div>
                                                        <div className="col-md-3 t-mb-15 mb-md-0">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => {
                                                                    setEndDate(date)
                                                                    dateRange(date, 'end')
                                                                }}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                className="form-control sm-text py-2"
                                                                placeholderText={_t(t("To date"))}
                                                                shouldCloseOnSelect={false}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 t-mb-15 mb-md-0">
                                                            <div className="input-group">
                                                                <div className="form-file">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 form-control--light-1 rounded-0"
                                                                        placeholder={_t(t("Search")) + ".."}
                                                                        onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <button
                                                                    className="btn btn-primary"
                                                                    type="button">
                                                                    <i className="fa fa-search"
                                                                       aria-hidden="true"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Table */}
                                            {newTransfer.uploading === true || loading === true ? (
                                                tableLoading()
                                            ) : (
                                                <div className="table-responsive">
                                                    <table
                                                        className="table table-bordered table-hover min-table-height">
                                                        <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                #
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("reason"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("date"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("type"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("account"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("amount"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("status"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border">
                                                                {_t(t("created_by"))}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {TransactionList && [
                                                            TransactionList?.data?.length === 0 ? (
                                                                <tr className="align-middle">
                                                                    <td
                                                                        scope="row"
                                                                        colSpan="9"
                                                                        className="xsm-text align-middle text-center"
                                                                    >
                                                                        {_t(t("No data available"))}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                TransactionList?.data?.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <tr
                                                                                className="align-middle"
                                                                                key={index}
                                                                            >
                                                                                <th
                                                                                    scope="row"
                                                                                    className="xsm-text text-capitalize align-middle text-center"
                                                                                >
                                                                                    {index +
                                                                                        1 +
                                                                                        (TransactionList.meta.current_page -
                                                                                            1) *
                                                                                        TransactionList.meta.per_page}
                                                                                </th>

                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.reason}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {moment(item.transactionDate).format("Do MMM, YYYY")}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.type === 1 ?
                                                                                        <span
                                                                                            className="badge bg-success">{_t(t("credit"))}</span>
                                                                                        :
                                                                                        <span
                                                                                            className="badge bg-danger">{_t(t("debit"))}</span>
                                                                                    }
                                                                                </td>
                                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                                    {item.account.label}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {currencySymbolLeft()}
                                                                                    {formatPrice(item.amount)}
                                                                                    {currencySymbolRight()}
                                                                                </td>
                                                                                <td className="xsm-text align-middle text-center">
                                                                                    {item.status === 1 ?
                                                                                        <span
                                                                                            className="badge bg-success">{_t(t("active"))}</span>
                                                                                        :
                                                                                        <span
                                                                                            className="badge bg-danger">{_t(t("in_active"))}</span>
                                                                                    }
                                                                                </td>


                                                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                                                    {item.user.name}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )
                                                            ),
                                                        ]}
                                                        </tbody>
                                                    </table>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* pagination loading effect */}
                            {newTransfer.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedTransfer.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}
                                                        {pagination(
                                                            TransactionList.meta,
                                                            setPaginatedAccount
                                                        )}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(TransactionList.meta)}
                                  </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => {
                                                                    setsearchedTransfer({
                                                                        ...searchedTransfer,
                                                                        searched: false,
                                                                    })
                                                                    setSearch('');
                                                                    handleSearch()
                                                                }

                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(
                                      TransactionList
                                  )}
                                </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                        {/* Rightbar contents end*/}
                    </div>
                </div>
            </main>
            {/* main body ends */
            }
        </>
    )
        ;
};

export default TransactionList;
