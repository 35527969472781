import {useCallback, useEffect, useState} from 'react';

function useSafeAsyncRequest() {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => setIsMounted(false);
    }, []);

    return useCallback(async (url, options) => {
        try {
            const response = await window.axiosInstance.get(url, options);

            return response?.data ?? response;

        } catch (error) {
            console.log(error.response)

            if (isMounted) {
                throw error;
            }
        }
    }, [isMounted]);
}
export { useSafeAsyncRequest };
